const getColorTheme = id => {
  let colors = {};
  switch (id) {
    case 'edc': // école de condé
      colors = {
        brand: {
          1: {
            500: '#c43683',
            600: '#A82D70',
            200: '#e5bbd2',
            100: '#fae4f0',
          },
        },
      };
      break;
    case 'ecv': // école de communication visuelle
      colors = {
        brand: {
          1: {
            500: '#8c74d9',
            600: '#7761BD',
            200: '#b6adda',
            100: '#E3DFF3',
          },
        },
      };
      break;

    case 'eac': // école art et culture
      colors = {
        brand: {
          1: {
            500: '#f1313f',
            600: '#ce2e3a',
            200: '#edc8ca',
            100: '#f7e6e7',
          },
        },
      };
      break;

    case 'esd': // es digital
      colors = {
        brand: {
          1: {
            500: '#4ac3b5',
            600: '#3DAC9F',
            200: '#bfe8e5',
            100: '#e1f6f4',
          },
        },
      };
      break;
    case 'ing': // in gemologie
      colors = {
        brand: {
          1: {
            500: '#9d7b52',
            600: '#886944',
            200: '#ebd5b9',
            100: '#faf3ea',
          },
        },
      };
      break;
    case 'esp': // es pub
      colors = {
        brand: {
          1: {
            500: '#29387d',
            600: '#2F3B75',
            200: '#9fabe2',
            100: '#e4e7f7',
          },
        },
      };
      break;
    case 'esparf': // es parfum
      colors = {
        brand: {
          1: {
            500: '#4ac3b5',
            600: '#3DAC9F',
            200: '#b9e2dd',
            100: '#dff8f5',
          },
        },
      };
      break;
    case 'sae': // es parfum
      colors = {
        brand: {
          1: {
            500: '#333333',
            600: '#111111',
            200: '#CCCCCC',
            100: '#EAEAEA',
          },
        },
      };
      break;

    // esd : #4edabd
    // ecv : #4bacef
    // ing : #86654e
    // esp : #292e7c
    // espa : #000000

    default:
      colors = {
        brand: {
          1: {
            500: '#3bac8f',
            600: '#4bd0ae',
            200: '#cdf7ec',
            100: '#e9f8f4',
          },
        },
      };
      break;
  }
  return colors;
};

export default getColorTheme;
