import React, { Component } from 'react';
import { Formik, Form } from 'formik';
import { Box, Button, Stack, Text } from '@chakra-ui/react';
import * as yup from 'yup';
import ProgressBar from './ProgressBar';
import Step0 from './Step0';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';

import { animateScroll as scroll } from 'react-scroll';
import axios from 'axios';
import isEnable from './isStepButtonEnable.js';
import { FormattedMessage, injectIntl } from 'react-intl';
import Debug from './debug';
import getAge from '../../Utils/getAge';

class StudentForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 0,
    };
  }

  componentDidUpdate() {
    // console.log('componentDidUpdate', this.props)
  }

  render() {
    const { intl } = this.props;
    return (
      <Formik
        validate={values => {}}
        initialValues={{
          status: this.props.initialValues.status
            ? this.props.initialValues.status
            : '',
          type: this.props.initialValues.type
            ? this.props.initialValues.type
            : '',
          firstName: this.props.initialValues
            ? this.props.initialValues.firstName
            : '',
          firstName2: this.props.initialValues
            ? this.props.initialValues.firstName2
            : '',
          firstName3: this.props.initialValues
            ? this.props.initialValues.firstName3
            : '',
          useFirstName: this.props.initialValues
            ? this.props.initialValues.useFirstName
            : '',
          useLastName: this.props.initialValues
            ? this.props.initialValues.useLastName
            : '',
          lastName: this.props.initialValues
            ? this.props.initialValues.lastName
            : '',
          email: this.props.initialValues ? this.props.initialValues.email : '',
          birthPlace_country:
            this.props.initialValues &&
            this.props.initialValues.birthPlace_country
              ? this.props.initialValues.birthPlace_country
              : null,
          birthPlace_postalCode: this.props.initialValues
            ? this.props.initialValues.birthPlace_postalCode
            : '',
          birthPlace_city: this.props.initialValues
            ? this.props.initialValues.birthPlace_city
            : '',
          birthDate: this.props.initialValues.birthDate
            ? this.props.initialValues.birthDate
            : '',
          nationality: this.props.initialValues.nationality
            ? this.props.initialValues.nationality
            : '',
          // birthDate: this.props.initialValues.birthDate ? new Date( this.props.initialValues.birthDate ) : '',
          postalCode: this.props.initialValues
            ? this.props.initialValues.postalCode
            : '',
          gender: this.props.gender
            ? this.props.initialValues.gender.toUpper()
            : '',
          city: this.props.initialValues ? this.props.initialValues.city : '',
          country: this.props.initialValues.country
            ? `${this.props.initialValues.country.toUpperCase()}`
            : null,
          address: this.props.initialValues
            ? this.props.initialValues.address
            : '',
          phonePrefix:
            this.props.initialValues &&
            this.props.initialValues.phonePrefix != ''
              ? this.props.initialValues.phonePrefix
              : '+33',
          phone: this.props.initialValues ? this.props.initialValues.phone : '',
          gender: this.props.initialValues
            ? this.props.initialValues.gender
            : '',
          mailRecipient: this.props.initialValues
            ? this.props.initialValues.mailRecipient
            : '',
          idCardPictFile: this.props.initialValues
            ? this.props.initialValues.idCardPictFile.value
            : null,

          profilePictFileCropped: this.props.initialValues
            ? this.props.initialValues.profilePictFile
            : null,
          selfPayStudies: this.props.initialValues
            ? this.props.initialValues.selfPayStudies
            : null,

          responsibles:
            this.props.initialValues.responsibles &&
            this.props.initialValues.responsibles.length > 0
              ? this.props.initialValues.responsibles
              : [
                  {
                    email: '',
                    phonePrefix: '+33',
                  },
                ],
          validateResponsibles: false,

          schoolChart: undefined,
          // schoolChartSign: '',
          wifiChart: undefined,
          imageRights: undefined,
          exactInfoCertificate: undefined,
          cgv: undefined,
          personnalDataChart: undefined,
          otherChart: undefined,

          // schoolChartSign: null,
          // wifiChartSign: null,
          // imageRightsSign: null,
          // cgvSign: null,
          // personnalDataChartSign: null,
          // otherChartSign: null,
          age: 0,
          signatures: this.props.signatures,
        }}
        onSubmit={(values, actions) => {
          axios
            .patch(
              `${process.env.REACT_APP_API_URL}/inscription_biel.php/student/${this.props.studentId}`,
              values
            )
            .then(() => {
              this.props.redirectToPayment();
            });
        }}
        validationSchema={yup.object().shape({
          firstName: yup
            .string(intl.formatMessage({ id: 'required.field' }))
            .required(intl.formatMessage({ id: 'required.field' }))
            .nullable(),
          firstName2: yup
            .string(intl.formatMessage({ id: 'required.field' }))
            .nullable(),
          firstName3: yup
            .string(intl.formatMessage({ id: 'required.field' }))
            .nullable(),
          lastName: yup
            .string(intl.formatMessage({ id: 'required.field' }))
            .required(intl.formatMessage({ id: 'required.field' }))
            .nullable(),
          // birthDate: yup
          //     .string()
          //     .matches(
          //         /[0-9]{4}-[0-9]{2}-[0-9]{2}/,
          //         intl.formatMessage({ id: "label.birthdate.format.error" })
          //     )
          //     .required(intl.formatMessage({ id: "required.field" }))
          //     .nullable(),

          birthDate: yup
            .date()
            .required(intl.formatMessage({ id: 'required.field' }))
            .min(
              new Date(Date.now() - 3153600000 * 1000),
              intl.formatMessage({ id: 'required.field' })
            )
            .max(
              new Date(Date.now() - 473040000 * 1000),
              intl.formatMessage({ id: 'required.field' })
            )
            .nullable(),

          birthPlace_postalCode: yup
            .string(intl.formatMessage({ id: 'required.field' }))
            // .min(2, 'Le code postal est trop court')
            .max(20, 'Le code postal est trop long')
            .required(intl.formatMessage({ id: 'required.field' }))
            .nullable()
            .test('forbiden-postal-code', function (value) {
              let isValid = ![
                `75`,
                `75000`,
                `69`,
                `69000`,
                `13`,
                `13000`,
              ].includes(value);
              if (isValid) return true;
              return this.createError({
                path: this.path,
                message: `Pouvez-vous renseigner le code postal en incluant l'arrondissement, ex: ${value.slice(
                  0,
                  2
                )}001 pour le premier arrondissement.`,
              });
            }),
          birthPlace_city: yup
            .string(intl.formatMessage({ id: 'required.field' }))
            .required(intl.formatMessage({ id: 'required.field' }))
            .nullable(),
          birthPlace_country: yup
            .string(intl.formatMessage({ id: 'required.field' }))
            .required(intl.formatMessage({ id: 'required.field' }))
            .nullable(),

          age: yup
            .number(intl.formatMessage({ id: 'required.field' }))
            .nullable(),
          gender: yup
            .string(intl.formatMessage({ id: 'required.field' }))
            .required(intl.formatMessage({ id: 'required.field' }))
            .nullable(),

          address: yup
            .string(intl.formatMessage({ id: 'required.field' }))
            .required(intl.formatMessage({ id: 'required.field' }))
            .nullable(),
          postalCode: yup
            .string(intl.formatMessage({ id: 'required.field' }))
            .required(intl.formatMessage({ id: 'required.field' }))
            .nullable(),
          city: yup.string(intl.formatMessage({ id: 'required.field' })),
          country: yup
            .string(intl.formatMessage({ id: 'required.field' }))
            .required(intl.formatMessage({ id: 'required.field' }))
            .nullable(),
          nationality: yup
            .string(intl.formatMessage({ id: 'required.field' }))
            .required(intl.formatMessage({ id: 'required.field' }))
            .nullable(),
          phonePrefix: yup
            .string(intl.formatMessage({ id: 'required.field' }))
            .required(intl.formatMessage({ id: 'required.field' }))
            .nullable(),
          phone: yup
            .string(intl.formatMessage({ id: 'required.field' }))
            .min(8, intl.formatMessage({ id: 'required.field' }))
            .required(intl.formatMessage({ id: 'required.field' })),
          profilePictFileCropped: yup
            .mixed()
            .required(intl.formatMessage({ id: 'required.field' })),

          idCardPictFile: yup
            .mixed(intl.formatMessage({ id: 'required.field' }))
            .required(intl.formatMessage({ id: 'required.field' }))
            .nullable(),

          ...(!this.props.initialValues.skipResponsiblesStep && {
            responsibles: yup
              .array()
              .of(
                yup.object({
                  firstName: yup
                    .string()
                    .required(intl.formatMessage({ id: 'required.field' })),
                  lastName: yup
                    .string()
                    .required(intl.formatMessage({ id: 'required.field' })),
                  address: yup
                    .string()
                    .required(intl.formatMessage({ id: 'required.field' })),
                  postalCode: yup
                    .string()
                    .required(intl.formatMessage({ id: 'required.field' })),
                  city: yup.string(),
                  country: yup
                    .string()
                    .required(intl.formatMessage({ id: 'required.field' })),
                  phonePrefix: yup
                    .string()
                    .required(intl.formatMessage({ id: 'required.field' })),
                  phone: yup
                    .string(intl.formatMessage({ id: 'required.field' }))
                    .required(intl.formatMessage({ id: 'required.field' }))
                    .min(8, intl.formatMessage({ id: 'required.field' }))
                    .nullable(),
                  email: yup
                    .string()
                    .email('Email valide requis')
                    .required(intl.formatMessage({ id: 'required.field' })),
                  relationshipType: yup
                    .string()
                    .required(intl.formatMessage({ id: 'required.field' }))
                    .nullable(),
                  isStudiesPayer: yup
                    .boolean()
                    .required(intl.formatMessage({ id: 'required.field' })),
                })
              )
              .max(2)
              .min(0, 'Vous devez saisir au moins '),
          }),
          validateResponsibles: yup.bool(),
          schoolChart: yup
            .bool()
            .oneOf([true], intl.formatMessage({ id: 'required.field' })),
          wifiChart: yup
            .bool()
            .oneOf([true], intl.formatMessage({ id: 'required.field' })),
          imageRights: yup.bool(),
          cgv: yup.bool().required(),
          exactInfoCertificate: yup
            .bool()
            .oneOf([true], intl.formatMessage({ id: 'required.field' })),
        })}
      >
        {({
          handleSubmit,
          handleChange,
          resetForm,
          touched,
          values,
          errors,
          isSubmitting,
          setFieldValue,
          setFieldTouched,
          setFieldError,
          isValid,
        }) => (
          <Form noValidate autoComplete="off">
            <Box position="relative">
              {/* <Debug data={this.props.initialValues} /> */}
              <ProgressBar
                hasResponsiblesStep={
                  !this.props.initialValues.skipResponsiblesStep
                }
                step={this.state.step}
                errors={errors}
                values={values}
              />
              <Stack spacing={10} p={6}>
                {this.state.step > 0 ? (
                  <Box>
                    <Button
                      size="sm"
                      onClick={() => {
                        this.setState({
                          step:
                            this.state.step === 3 &&
                            this.props.initialValues.skipResponsiblesStep
                              ? 1
                              : this.state.step - 1,
                        });
                      }}
                    >
                      ← <FormattedMessage id="button.back" />
                    </Button>
                  </Box>
                ) : null}
                {this.state.step === 0 ? (
                  <Step0
                    values={values}
                    school={this.props.initialValues.school}
                    diploma={this.props.initialValues.diplomaName}
                    campus={this.props.initialValues.campus}
                    student={this.props.student}
                  />
                ) : this.state.step === 1 ? (
                  <Step1
                    values={values}
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    setFieldError={setFieldError}
                    school={this.props.initialValues.school}
                    studentId={this.props.studentId}
                    settings={this.props.initialValues}
                  />
                ) : this.state.step === 2 ? (
                  <Step2
                    values={values}
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    setFieldError={setFieldError}
                    settings={this.props.initialValues}
                    school={this.props.initialValues.school}
                  />
                ) : this.state.step === 3 ? (
                  <Step3
                    values={values}
                    errors={errors}
                    touched={touched}
                    isValid={isValid}
                    student={this.props.student}
                    school={this.props.initialValues.school}
                    initialValues={this.props.initialValues}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    setFieldError={setFieldError}
                    isSubmitting={isSubmitting}
                  />
                ) : this.state.step === 4 ? (
                  <Step4 values={values} errors={errors} touched={touched} />
                ) : null}
                {this.state.step < 3 ? (
                  <Box>
                    <Button
                      colorScheme="blue"
                      background="brand.1.500"
                      disabled={!isEnable(this.state.step, errors, values)}
                      _hover={{
                        bg: 'brand.1.600',
                      }}
                      onClick={() => {
                        scroll.scrollToTop();
                        this.setState({
                          step:
                            this.state.step === 1 &&
                            this.props.initialValues.skipResponsiblesStep
                              ? 3
                              : this.state.step + 1,
                        });
                      }}
                    >
                      <FormattedMessage id="button.next.step" /> →
                    </Button>
                  </Box>
                ) : null}

                <Debug data={errors} />
              </Stack>
            </Box>
          </Form>
        )}
      </Formik>
    );
  }
}

export default injectIntl(StudentForm);
