import * as React from 'react';
import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  SimpleGrid,
  Stack,
  Text,
} from '@chakra-ui/react';
import Debug from '../Form/debug';
import dayjs from 'dayjs';
import { FormattedMessage, useIntl } from 'react-intl';
import DocumentReadingModal from '../DocumentReadingModal';

require('dayjs/locale/fr');
dayjs.locale('fr');

const dataMatch = [
  {
    key: 'firstName',
    displayName: 'Prénom',
  },
  {
    key: 'firstName_2',
    displayName: 'Deuxième prénom',
  },
  {
    key: 'firstName_3',
    displayName: 'Troisième prénom',
  },
  {
    key: 'lastName',
    displayName: 'Nom',
  },
  {
    key: 'city',
    displayName: 'Ville',
  },
  {
    key: 'birthPlace_country',
    displayName: 'Pays de naissance',
  },
  {
    key: 'birthPlace_postalCode',
    displayName: 'Code postal de la localité de naissance',
  },
  {
    key: 'birthPlace_city',
    displayName: 'Nom de la localité de naissance',
  },
  {
    key: 'birthDate',
    displayName: 'Date de naissance',
    transformer: value => dayjs(value).format('DD MMMM YYYY'),
  },
  {
    key: 'nationality',
    displayName: 'Nationalité',
  },
  {
    key: 'postalCode',
    displayName: 'Code postal de la localité de résidence',
  },
  {
    key: 'city',
    displayName: 'Localité de résidence',
  },
  {
    key: 'country',
    displayName: 'Pays de résidence',
  },
  {
    key: 'address',
    displayName: 'Adresse',
  },
  {
    key: 'phone',
    displayName: 'Numéro de téléphone',
  },
  {
    key: 'gender',
    displayName: 'Genre',
  },
];

const subjects = dataMatch.map(item => item.key);

const Line = ({ subject, value }) => {
  // console.log(typeof value, value)
  console.log('subject', subject, value);
  if (
    typeof value !== 'string' ||
    // && subject !== 'firstName'
    subjects.indexOf(subject) === -1 ||
    !value
  )
    return null;
  else
    return (
      <>
        <Flex justify={'space-between'} fontSize={'sm'}>
          <Box color={`gray.500`}>
            {dataMatch.filter(item => item.key === subject)[0].displayName}
          </Box>
          <Box color={`gray.800`} fontWeight={'600'}>
            {!dataMatch.filter(item => item.key === subject)[0].transformer
              ? value
              : dataMatch
                  .filter(item => item.key === subject)[0]
                  .transformer(value)}
          </Box>
        </Flex>
        <Divider />
      </>
    );
  // return (<Box>Subject : {subject} / Value : {value}</Box>)
};

const ResponsiblesDataSummary = ({ data }) => {
  if (!data || data.length === 0) return null;
  return (
    <Stack spacing={4}>
      <Heading fontSize={'md'}>Reponsables :</Heading>
      <SimpleGrid gap={8} columns={{ base: 1, lg: data.length }}>
        {data.map((person, index) => (
          <Stack spacing={2} key={`person-${index}`}>
            {Object.keys(person).map((keyName, i) => (
              <Line key={i} subject={keyName} value={person[keyName]} />
            ))}
          </Stack>
        ))}
      </SimpleGrid>
    </Stack>
  );
};

export const DataSummary = ({ data, visibleOnLoad }) => {
  const [visible, setVisible] = React.useState(visibleOnLoad);
  if (!data) return;
  return (
    <Stack id="data-summary" spacing={2}>
      <Button onClick={() => setVisible(!visible)}>
        Rappel des informations saisies
      </Button>
      <Stack
        display={!visible ? `none` : `block`}
        bgColor={`white`}
        rounded={'md'}
        shadow={'md'}
        p={4}
        spacing={10}
      >
        <Stack spacing={2}>
          {Object.keys(data).map((keyName, i) => (
            <Line key={i} subject={keyName} value={data[keyName]} />
          ))}
        </Stack>
        <ResponsiblesDataSummary data={data.responsibles} />
        {/* <Debug data={data.responsibles} /> */}
      </Stack>
    </Stack>
  );
};

export const DocumentsSummary = ({ data, visibleOnLoad }) => {
  const translations = useIntl()['messages'];
  const locale = useIntl()['locale'];
  const [visible, setVisible] = React.useState(visibleOnLoad);
  const initialValues = data;

  const [currentModal, setCurrentModal] = React.useState(false);
  const [modalData, setModalData] = React.useState(false);

  let fieldsGroupOne = [
    ...(data.signatures?.schoolChart?.signed
      ? [
          {
            name: 'schoolChart',
            label: translations['internal.regulation'],
            btnLabel: translations['read.sign'],
            signImageField: 'schoolChartSign',
            modal: 'school',
            mandatory: true,
            readOnly: true,
            allowSign: false,
            userInputs: data.signatures.schoolChart,
          },
        ]
      : []),
    ...(data.signatures?.wifiChart?.signed
      ? [
          {
            name: 'wifiChart',
            label: translations['internal.wifi.chart'],
            btnLabel: translations['read.sign'],
            signImageField: 'wifiChartSign',
            modal: 'wifi',
            mandatory: true,
            readOnly: true,
            allowSign: false,
            userInputs: data.signatures.wifiChart,
          },
        ]
      : []),
    ...(data.signatures?.imageRight?.signed
      ? [
          {
            name: 'imageRights',
            label: translations['image.rights'],
            btnLabel: translations['read.chart'],
            signImageField: 'imageRightsSign',
            modal: 'picture',
            mandatory: false,
            readOnly: true,
            allowSign: false,
            userInputs: data.signatures.imageRight,
          },
        ]
      : []),
    ...(data.signatures?.personnalDataChart?.signed
      ? [
          {
            name: 'personnalDataChart',
            label: translations['personnal.data.chart'],
            btnLabel: translations['read.chart'],
            signImageField: 'personnalDataChartSign',
            modal: 'personnalDataChart',
            mandatory: true,
            readOnly: true,
            allowSign: false,
            userInputs: data.signatures.wifiChart,
          },
        ]
      : []),
    ...(data.signatures?.otherChart?.signed
      ? [
          {
            name: 'otherChart',
            label: translations['laboratory.regulation'],
            btnLabel: translations['read.sign'],
            signImageField: 'otherChartSign',
            modal: 'other',
            mandatory: true,
            readOnly: true,
            allowSign: false,
            userInputs: data.signatures.otherChart,
          },
        ]
      : []),
    ...(data.signatures?.cgvChart?.signed
      ? [
          {
            name: 'cgv',
            label: translations['cgv'],
            btnLabel: translations['read.sign'],
            signImageField: 'cgvSign',
            modal: 'cgv',
            mandatory: true,
            readOnly: true,
            allowSign: false,
            userInputs: data.signatures.wifiChart,
          },
        ]
      : []),
  ];
  if (
    initialValues.school.sandwishTraining &&
    initialValues.school.isCgvChartEnabled
  ) {
    fieldsGroupOne.push({
      name: 'cgv',
      label: translations['sandwish.training.cgv'],
      btnLabel: translations['read.sign'],
      signImageField: 'cgvSign',
      modal: 'cgv',
      mandatory: true,
      readOnly: true,
      userInputs: data.signatures.cgvChart,
    });
  }

  if (!data) return;
  return (
    <>
      {currentModal ? (
        <DocumentReadingModal
          firstName={data.firstName}
          lastName={data.lastName}
          isOpen={currentModal ? true : false}
          // title={ <FormattedMessage id="internal.regulation" /> }
          onClose={() => {
            setCurrentModal(false);
          }}
          url={
            currentModal === 'school'
              ? data.school.schoolChartUrl
              : currentModal === 'wifi'
              ? data.school.wifiChartUrl
              : currentModal === 'cgv'
              ? data.school.cgvChartUrl
              : currentModal === 'personnalDataChart'
              ? data.school.personalDataChartUrl
              : currentModal === 'other'
              ? data.school.otherChartUrl
              : data.school.imageRightChartUrl
          }
          handleSign={signFile => {}}
          handleDecline={() => {
            // setFieldValue(currentFieldSign, false);
          }}
          translations={translations}
          key={modalData}
          modalData={modalData}
          locale={locale}
          allowSign={false}
        />
      ) : null}
      <Stack id="data-summary" spacing={2}>
        <Button onClick={() => setVisible(!visible)}>
          Rappel des documents signés
        </Button>
        <Stack
          display={!visible ? `none` : `block`}
          bgColor={`white`}
          rounded={'md'}
          shadow={'md'}
          p={4}
          spacing={10}
        >
          <Stack spacing={2}>
            {fieldsGroupOne.map((doc, i) => (
              <Flex
                justify="space-between"
                borderBottom={i + 1 < fieldsGroupOne.length ? 'solid 1px' : ``}
                borderBottomColor="gray.100"
                pb={2}
              >
                <Text>{doc.label}</Text>
                <Button
                  size="sm"
                  onClick={e => {
                    if (doc.modal) {
                      e.preventDefault();
                      setCurrentModal(doc.modal);
                      setModalData(doc);
                    }
                  }}
                >
                  Visualiser
                </Button>
                {/* <Debug data={field} /> */}
              </Flex>
            ))}
          </Stack>
          {/* <Debug data={data.responsibles} /> */}
        </Stack>
      </Stack>
    </>
  );
};
