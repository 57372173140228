import { Flex, Heading, Image, Text, VStack } from '@chakra-ui/react';

export const HeaderUnsubscribedForm = ({ data }) => (
  <VStack spacing={4} textAlign="center">
    <Flex justify="center" bgColor="white" borderRadius="full">
      <Image
        w="160px"
        h="160px"
        objectFit="contain"
        src={data.school.logoUrl}
        alt={data.school.name}
      />
    </Flex>
    <VStack spacing={0} textAlign="center">
      <Text color={'brand.1.500'} fontWeight="500" fontSize="sm">
        Vous souhaitez vous désinscrire ?
      </Text>
      <Heading fontSize={{ base: `lg`, lg: `lg` }}>
        Procédez ici à l'annulation de votre inscription
      </Heading>
    </VStack>
    <Text color="gray.600" fontSize="sm">
      Nous sommes navrés de ne pas vous accueillir à la rentrée. Afin de
      confirmer votre désinscription, veuillez nous préciser ici le motif de
      votre décision.
    </Text>
  </VStack>
);
