import { Crisp } from "crisp-sdk-web";
Crisp.configure(`478af7cb-35c9-4254-b863-63e82b6a86a9`);
export const CrispAction = (apiData) => {
    if (!apiData
        //  Object(apiData).keys.length < 1
    ) { return null }
    // console.log('crisp is loaded', apiData);

    Crisp.user.setEmail(apiData?.email);
    Crisp.user.setNickname(`${apiData?.firstName} ${apiData?.lastName}`);

    Crisp.session.setData({
        niveau: apiData.level ?? 'N/A',
        ecole: apiData.school.code ?? 'N/A',
        // campus: apiData.campus ?? 'N/A',
        formation: apiData.diplomaName ?? 'N/A',
        type: apiData.type ?? 'N/A',
    });
}

// Crisp.user.setNickname(`${backendData.student.firstName} ${backendData.student.lastName}`);
// Crisp.user.setEmail(backendData.student.email);
// Crisp.user.setPhone(backendData.student.phone);
// Crisp.session.setData({
//   niveau: backendData.student.level ?? 'N/A',
//   ecole: backendData.school.name ?? 'N/A',
//   campus: backendData.student.campus ?? 'N/A',
//   formation: backendData.student.formation ?? 'N/A',
//   type: backendData.student.type ?? 'N/A',
// });