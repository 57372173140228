import * as React from 'react';
import { BsInfo } from 'react-icons/bs';

import {
  Box,
  VStack,
  Text,
  HStack,
  Button,
  Center,
  Modal,
  ModalBody,
  ModalOverlay,
  ModalContent,
  Heading,
  Textarea,
  ButtonGroup,
} from '@chakra-ui/react';
import axios from 'axios';
import { BiCheckCircle, BiXCircle } from 'react-icons/bi';

export const ChangeChoiceModal = ({ isVisible, handleClose, sessionId }) => {
  const [input, setInput] = React.useState(``);
  const handleSubmit = async () => {
    const backendRequest = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/v1/BREL/Post_UnsubscribeDataReactivateSubscription/${sessionId}`,
      {
        sessionId: sessionId,
        message: input,
      },
      {
        headers: { 'X-API-KEY': 'f3066df0984239de3804c99a720b23a4d1c26d63' },
      }
    );
    if (backendRequest.status === 200) handleClose();
  };

  return (
    <Modal size="2xl" isOpen={isVisible} onClose={() => console.log('click')}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody p={{ base: 4, lg: 10 }}>
          <VStack spacing={{ base: 4, lg: 6 }} align="flex-start">
            <Heading fontSize={{ base: `md`, lg: `xl` }} fontWeight={500}>
              Vous voulez réactiver votre inscription ?
            </Heading>
            <Text color="gray.600">
              Nous vous recontacterons dans les plus brefs délais pour vous
              confirmer la disponibilité de la formation souhaitée.
            </Text>
            <Textarea
              placeholder={`N'hésitez pas à nous indiquer ici le meilleur moyen et moment pour vous contacter. Faites-nous part de toute information que vous jugerez utile pour accélérer votre parcours d'inscription.`}
              onChange={e => setInput(e.target.value)}
              value={input}
              minH={{ base: `200px`, lg: `220px` }}
            />
            <ButtonGroup justifyContent="flex-end" w="full" size="sm">
              <Button onClick={() => handleClose()}>
                <Box mr={1}>
                  {' '}
                  <BiXCircle />
                </Box>
                Annuler
              </Button>
              <Button
                bgColor={`brand.1.500`}
                color={`white`}
                disabled={!input}
                onClick={() => handleSubmit()}
              >
                <Box mr={1}>
                  <BiCheckCircle />
                </Box>
                Confirmer
              </Button>
            </ButtonGroup>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export const ChangeChoiceBanner = ({ isVisible, sessionId }) => {
  const [isModalVisible, setIsModalVisible] = React.useState(isVisible);
  return (
    <>
      <ChangeChoiceModal
        sessionId={sessionId}
        isVisible={isModalVisible}
        handleClose={() => setIsModalVisible(false)}
      />
      <HStack
        p="4"
        //   bgColor="#F6EFF6"
        bgColor="brand.1.100"
        color="brand.1.500"
        border="solid 1px"
        borderColor="brand.1.200"
        borderRadius="md"
        spacing={[0, 4]}
        // style={{ alignItems: 'flex-start' }}
        wrap={['wrap', 'nowrap']}
        alignItems="center"
      >
        <Center display={{ base: `none`, lg: `block` }}>
          <Box bgColor="brand.1.200" p={1} borderRadius={'md'} lineHeight={0}>
            <BsInfo width={3} height={3} />
          </Box>
        </Center>

        <VStack spacing={'2'} alignItems="flex-start">
          <Text fontSize="xs" mb={{ base: 2, lg: 0 }}>
            Si vous avez changé d’avis, vous pouvez nous indiquer votre souhait
            de réactiver votre inscription en cliquant sur le bouton ci-contre.
          </Text>
        </VStack>
        <Box>
          <Button
            size="sm"
            color="brand.1.500"
            bgColor="brand.1.200"
            onClick={() => setIsModalVisible(!isModalVisible)}
            _hover={{ bgColor: 'brand.1.600', color: 'white' }}
          >
            J'ai changé d'avis
          </Button>
        </Box>
      </HStack>
    </>
  );
};
