import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  HStack,
  Stack,
  Text,
} from '@chakra-ui/react';
import Debug from '../../Form/debug';
import FieldBlock from '../../Teacher/FieldBlock';
import { DownloadIcon, ExternalLinkIcon } from '@chakra-ui/icons';
import { Section, SectionTitle } from '../ProfileForm';
import { BankChequeNotice, BankWireNotice } from './paymentMethodNotices';

export const ScheduleAppInfos = ({ paymentOptions, school, data }) => {
  const InitBlock = () => (
    <Stack spacing={6}>
      <Text>
        Vous devez valider{' '}
        <strong>vos modalités de règlement des frais de scolarité</strong>{' '}
        <em>
          (paiement en une ou plusieurs fois, dates et types de versement, choix
          d'options...)
        </em>
        )
      </Text>
      <Text>
        Cliquez sur le bouton ci-dessous pour personnaliser votre échéancier.
      </Text>

      <FormControl>
        <Button
          colorScheme="green"
          size="sm"
          as={'a'}
          target="_blank"
          href={paymentOptions.paymentAppUrl}
        >
          Accéder à l'échéancier
          <ExternalLinkIcon ml={2} />
        </Button>
        <FormHelperText fontSize="sm">
          Ce lien va ouvrir un onglet externe à l'application .
        </FormHelperText>
      </FormControl>
    </Stack>
  );
  const SummaryBlock = () => (
    <Stack spacing={6}>
      <Text>
        <strong>Vous avez complété et validé votre échéancier.</strong>
        <br />
        En voici le récapitulatif.
      </Text>
      <Text>
        <strong>
          Nombre de versement{paymentOptions.paymentsNumber > 1 ? `s` : ``}
        </strong>{' '}
        : en {paymentOptions.paymentsNumber} fois
        <br />
        {paymentOptions.alumniPack && (
          <>
            {' '}
            <strong>Pack Alumni</strong> : {paymentOptions.alumniPack}
          </>
        )}
        <br />
        {paymentOptions.options && (
          <>
            <strong>Option(s)</strong> : {paymentOptions.options.join(', ')}
          </>
        )}
      </Text>

      <FormControl>
        <Stack direction={['column', 'row']} spacing={2} wrap={'wrap'}>
          <Button
            colorScheme="green"
            size="sm"
            as={'a'}
            target="_blank"
            href={paymentOptions.paymentAppUrl}
            width={{ xs: `100%`, lg: `auto` }}
            flexGrow={{ xs: `1`, lg: `auto` }}
          >
            Accéder à l'échéancier
            <ExternalLinkIcon ml={2} />
          </Button>
          {paymentOptions.scheduleFileUrl && (
            <Button
              size="sm"
              cursor="pointer"
              as={'a'}
              target="_blank"
              href={paymentOptions.scheduleFileUrl}
              width={{ xs: `100%`, lg: `auto` }}
              flexGrow={{ xs: `1`, lg: `auto` }}
            >
              Télécharger
              <DownloadIcon ml={2} />
            </Button>
          )}
        </Stack>
        <FormHelperText fontSize="sm">
          Ce lien va ouvrir un onglet externe à l'application .
        </FormHelperText>
      </FormControl>
      {paymentOptions.paymentMethod === 'bankWire' ? (
        <BankWireNotice school={school} data={data} />
      ) : paymentOptions.paymentMethod === 'bankCheque' ? (
        <>
          <BankChequeNotice school={school} data={data} />
        </>
      ) : (
        <>
          <BankWireNotice
            school={school}
            data={data}
            title="Si vous souhaitez payer par virement :"
          />
          <BankChequeNotice
            school={school}
            data={data}
            title="Si vous souhaitez payer par chèque :"
          />
        </>
      )}
    </Stack>
  );

  return (
    <Section>
      <SectionTitle>Échéancier</SectionTitle>
      <FieldBlock isValid={paymentOptions.isValid}>
        {!paymentOptions.isPaymentChoiceMade ? <InitBlock /> : <SummaryBlock />}
      </FieldBlock>
    </Section>
  );
};
