import { Box, Heading, List, ListItem, Text } from '@chakra-ui/react';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

export const Step1ErrorNotifications = ({ touched, errors }) => {
  return (
    <Box>
      {Object.keys(touched).length > 0 &&
      (errors.firstName ||
        errors.lastName ||
        errors.gender ||
        errors.birthDate ||
        errors.phone ||
        errors.birthPlace_postalCode ||
        errors.birthPlace_postalCode ||
        errors.postalCode ||
        errors.country ||
        errors.profilePictFileCropped ||
        errors.idCardPictFile ||
        errors.exactInfoCertificate ||
        errors.nationality) ? (
        <Box
          border="solid 2px"
          borderColor="red.300"
          bgColor="orange.50"
          p={4}
          rounded="md"
        >
          <Text>
            <Heading
              color="red.500"
              fontSize={'md'}
              textTransform="uppercase"
              letterSpacing={'wide'}
              mb={2}
            >
              <FormattedMessage id="missing.or.wrong.data" />:
            </Heading>
            <List listStyleType={'disc'} listStylePos="inside">
              {errors.firstName ? (
                <ListItem>
                  <FormattedMessage id="notice.missing.firstName" />
                </ListItem>
              ) : null}
              {errors.lastName ? (
                <ListItem>
                  <FormattedMessage id="notice.missing.lastName" />
                </ListItem>
              ) : null}
              {errors.gender ? (
                <ListItem>
                  <FormattedMessage id="notice.missing.gender" />
                </ListItem>
              ) : null}
              {errors.birthDate ? (
                <ListItem>
                  <FormattedMessage id="notice.missing.birthDate" />
                </ListItem>
              ) : null}
              {errors.nationality ? (
                <ListItem>
                  <FormattedMessage id="notice.missing.nationality" />
                </ListItem>
              ) : null}
              {errors.phone ? (
                <ListItem>
                  <FormattedMessage id="notice.missing.phone" />
                </ListItem>
              ) : null}
              {errors.birthPlace_postalCode ||
              errors.birthPlace_city ||
              errors.birthPlace_country ? (
                <ListItem>
                  <FormattedMessage id="notice.missing.birthPlace" />
                </ListItem>
              ) : null}

              {errors.postalCode ||
              errors.address ||
              errors.city ||
              errors.country ? (
                <ListItem>
                  <FormattedMessage id="notice.missing.address" />
                </ListItem>
              ) : null}
              {errors.profilePictFileCropped ? (
                <ListItem>
                  <FormattedMessage id="notice.missing.profilePictFile" />
                </ListItem>
              ) : null}
              {errors.idCardPictFile ? (
                <ListItem>
                  <FormattedMessage id="notice.missing.idPictFile" />
                </ListItem>
              ) : null}
              {errors.exactInfoCertificate ? (
                <ListItem>
                  <FormattedMessage id="notice.missing.exactInfoCertificate" />
                </ListItem>
              ) : null}
            </List>
          </Text>
        </Box>
      ) : null}
    </Box>
  );
};
