import React, { Component } from 'react';
import { ReactSketchCanvas } from 'react-sketch-canvas';
import {
  Box,
  Button,
  Input,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  SimpleGrid,
  Stack,
  Spinner,
  Text,
  HStack,
  FormControl,
  FormLabel,
} from '@chakra-ui/react';
import axios from 'axios';
import { FormattedMessage, useIntl } from 'react-intl';
import CanvasDraw from 'react-canvas-draw';
import html2canvas from 'html2canvas';
import css from '../Styles/wysiwyg.css';
import Debug from './Form/debug';
import dayjs from 'dayjs';

require('typeface-caveat');
const DocumentReadingModal = props => {
  const {
    title,
    firstName,
    lastName,
    isOpen,
    url,
    onClose,
    translations,
    modalData,
    handleDecline,
    locale,
    handleSign,
    allowSign,
  } = props;

  const [content, setContent] = React.useState();

  const handleClose = () => {
    setContent(null);
    onClose();
  };

  const saveSign = async () => {
    handleSign(true);
  };

  React.useEffect(() => {
    axios.get(url).then(response => {
      setContent(response.data);
    });
  }, []);

  return (
    <Modal size="4xl" isOpen={isOpen} onClose={() => handleClose()}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader bg="gray.50">
          {title ? title : null}
          <FormattedMessage id="document.sign.notice.2" />
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={20}>
          {content ? (
            <Box p={{ base: 0, lg: 15 }}>
              <div
                className={'wysiwyg'}
                dangerouslySetInnerHTML={{ __html: content }}
              />
            </Box>
          ) : (
            <Flex p={10} justify="center">
              <Spinner size="sm" />
            </Flex>
          )}
          <Stack
            spacing={{ base: 4, lg: 6 }}
            p={{ base: 0, lg: 15 }}
            // display={modalData.readOnly ? 'none' : 'block'}
          >
            {modalData.readOnly === false && (
              <Text>
                Signez électroniquement le document en cliquant sur le bouton
                "Valider votre signature" :
              </Text>
            )}

            <Box
              w={{ base: `full`, lg: `360px` }}
              bg="gray.100"
              borderRadius={`md`}
              px={6}
              py={4}
            >
              <Text
                textTransform=""
                color="gray.600"
                letterSpacing="wide"
                fontSize="sm"
                fontWeight={600}
                mb={6}
                display={modalData.readOnly ? 'none' : 'block'}
              >
                Voici la signature qui sera appliquée sur le document :
              </Text>
              <Text
                borderBottomColor="gray.100"
                fontFamily={`"Birthstone"`}
                fontWeight={600}
                fontSize={`3xl`}
                color="gray.700"
              >
                {`${firstName} ${lastName.toUpperCase()}`}
              </Text>
              <Text color="gray.500" fontSize="sm">
                <FormattedMessage id="read.and.approved.on" />{' '}
                {dayjs(modalData?.userInputs?.date).format('DD/MM/YYYY')}
              </Text>
            </Box>
            <Box display={!modalData.readOnly || allowSign ? 'block' : 'none'}>
              <Stack isInline wrap="wrap">
                <Button
                  onClick={() => {
                    saveSign();
                  }}
                  colorScheme="blue"
                  background="brand.1.500"
                  _hover={{
                    bg: 'brand.1.600',
                  }}
                >
                  <FormattedMessage id="confirm.your.signature" />
                </Button>
                {modalData.mandatory === false ? (
                  <Button variant="shadow" onClick={() => handleDecline()}>
                    <FormattedMessage id="i.dont.want.to.sign" />
                  </Button>
                ) : null}
              </Stack>
            </Box>
          </Stack>
          <Box
            display={modalData.readOnly && allowSign ? 'block' : 'none'}
            p={{ base: 5, lg: 15 }}
            pb={20}
          >
            <Button onClick={() => handleSign(null)}>
              <FormattedMessage id="i.have.read.this.document" />
            </Button>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
export default DocumentReadingModal;
