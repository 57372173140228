import { Box, Flex, FormControl, FormHelperText, FormErrorMessage, FormLabel, Grid, Input, Stack } from '@chakra-ui/react'
import { Field } from 'formik'
import * as React from 'react'
import Debug from './Form/debug'

export const RibField = ({ setHasUpdates }) => (

    <Stack>
        <Grid
            gridTemplateColumns={`70% 1fr`}
            gridGap={3}
        >
            <Field name={`ribIban`}>
                {({ form, field, meta }) => (
                    <FormControl isInvalid={meta.error && meta.touched ? true : false}>
                        <Input
                            placeholder='IBAN'
                            {...field}
                            onBlur={(e) => {
                                field.onBlur(e);
                                setHasUpdates(true);
                            }}
                        />
                        <FormErrorMessage>{meta.error}</FormErrorMessage>
                    </FormControl>
                )}
            </Field>
            <Field name={`ribBic`}>
                {({ form, field, handleChange }) => (
                    <Input
                        placeholder='Bic'
                        {...field}
                        onBlur={(e) => {
                            field.onBlur(e);
                            setHasUpdates(true);
                        }}
                    />
                )}
            </Field>
        </Grid>
        <Field name={`ribBankAddress`}>
            {({ form, field, handleChange }) => (
                <Input
                    placeholder='Domiciliation bancaire'
                    {...field}
                    onBlur={(e) => {
                        field.onBlur(e);
                        setHasUpdates(true);
                    }}
                />
            )}
        </Field>
    </Stack>
)