import React from 'react';
import Helmet from 'react-helmet';
import {
  ChakraProvider,
  extendTheme,
  Box,
  Flex,
  Grid,
  Image,
  Link,
} from '@chakra-ui/react';
import Footer from '../Footer';
import getColorTheme from '../../Utils/getColorTheme';
import getLogo from '../../Utils/getLogo';
import getFavicon from '../../Utils/getFavicon';
import Fonts from '../../Utils/Fonts';
import { IntlProvider, FormattedMessage } from 'react-intl';
import frTranslations from '../../Translations/fr';
import enTranslations from '../../Translations/en';
import Debug from '../Form/debug';

// export const UnsubscribedLayout = ({ children }) => (
//     <Flex minH="full" minW="full">
//       <Box>{children}</Box>
//     </Flex>
//   );

export const UnsubscribedLayout = props => {
  const { apiData, children } = props;
  const colors = getColorTheme(apiData.school ? apiData.school.code : '');
  const theme = extendTheme({
    colors,
    fonts: {
      // heading: 'AW Conqueror Didot'
    },
  });

  const translations = {
    en: enTranslations,
    fr: frTranslations,
  };

  return (
    <IntlProvider
      messages={translations[props.locale]}
      locale={props.locale}
      defaultLocale="fr"
    >
      <ChakraProvider theme={theme}>
        <Fonts />
        <Helmet>
          <link rel="icon" href={getFavicon(apiData.school.code)} />
        </Helmet>
        <Flex minH="full" minW="full" mx="auto" px={{ base: 4, lg: 0 }}>
          <Box mx="auto">{children}</Box>
        </Flex>
      </ChakraProvider>
    </IntlProvider>
  );
};
