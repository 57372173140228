import { Button, ButtonGroup } from '@chakra-ui/button';
import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
} from '@chakra-ui/form-control';
import { Input } from '@chakra-ui/input';
import {
  Box,
  Center,
  Divider,
  Flex,
  Heading,
  HStack,
  VStack,
  Stack,
  Text,
  Link as ChakraLink,
} from '@chakra-ui/layout';
import { Select } from '@chakra-ui/select';
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import BrowseAndJoinDocument from '../Form/BrowseAndJoinDocument';
import Debug from '../Form/debug';
import * as yup from 'yup';
import HighSchoolSelector from './HighSchoolSelector';
import {
  ArrowForwardIcon,
  CheckCircleIcon,
  SmallCloseIcon,
} from '@chakra-ui/icons';
import UpdateButton from './UpdateButton';
import ALevelSpeciality from './ALevelSpeciality';
import ALevelSpecialityWithOptions from './ALevelSpecialityWithOptions';
import HighSchoolLabel from './HighSchoolLabel';
import { FaInfo } from 'react-icons/fa';
import { IconTextBox } from '../IconTextBox.jsx';

import {
  Link,
  DirectLink,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
  Helpers,
} from 'react-scroll';
import axios from 'axios';
import Helper from './Helper';
import { useToast } from '@chakra-ui/toast';
import { SimpleGrid } from '@chakra-ui/react';
import { SchoolBankInformations } from './SchoolBankInformations';
import { RibField } from '../RibField';
import { AlumniFormulas } from './alumniFormulas';
import { ScheduleAppInfos } from './scheduleAppInfos/scheduleAppInfos';
import dayjs from 'dayjs';
import { LastDiplomaFieldBlock } from '../lastDiploma/index.js';
var IBAN = require('iban');

export const SectionTitle = ({ children }) => {
  return (
    <Heading fontSize="lg" mb={2} pt={4}>
      {children}
    </Heading>
  );
};

export const Section = ({ children, id }) => {
  return (
    <Element name={id}>
      <Box position="relative" id={id}>
        <Stack
          // p={10}
          // bg='white'
          // border='solid 1px'
          // borderColor='gray.100'
          // borderRadius='md'
          spacing={5}
        >
          {children}
        </Stack>

        <Divider my={10} />
      </Box>
    </Element>
  );
};

export const SectionLegend = ({ children }) => {
  return (
    <Box>
      <Text color="gray.700" fontSize="sm">
        {children}
      </Text>
    </Box>
  );
};

const ProfileForm = ({ settings, studentId }) => {
  const toast = useToast();
  const { school } = settings;
  const [hasUpdates, setHasUpdates] = useState(false);

  const FieldBlock = ({ children, isValid, id }) => {
    return (
      <Element name={id}>
        <Box
          p={{ base: 4, lg: 10 }}
          border="solid 1px"
          borderRadius="md"
          position="relative"
          borderColor={
            isValid === false
              ? 'yellow.200'
              : isValid === true
              ? 'green.100'
              : 'gray.100'
          }
          bg={
            isValid === false
              ? 'yellow.50'
              : isValid === true
              ? 'green.50'
              : 'white'
          }
        >
          <Box
            display={isValid === false || isValid === true ? 'block' : 'none'}
            bg={
              isValid === false
                ? 'yellow.400'
                : isValid === true
                ? 'green.400'
                : 'transparent'
            }
            position="absolute"
            top={-3}
            right={4}
            borderRadius="full"
            color="white"
          >
            <Flex px={2} py={{ base: 2, lg: 1 }} fontSize={{ base: 'sm' }}>
              <Center>
                <Text
                  fontWeight="bold"
                  fontSize={{ base: 'xs' }}
                  display={{ base: 'none', lg: 'block' }}
                >
                  {isValid === true
                    ? 'Valide'
                    : isValid === false
                    ? 'Invalide'
                    : ''}
                </Text>
                {isValid ? (
                  <CheckCircleIcon
                    ml={{ base: 0, lg: 1 }}
                    w={{ base: 4, lg: 3 }}
                    h={{ base: 4, lg: 3 }}
                  />
                ) : null}

                {isValid === false ? (
                  <SmallCloseIcon
                    ml={{ base: 0, lg: 1 }}
                    w={{ base: 4, lg: 3 }}
                    h={{ base: 4, lg: 3 }}
                  />
                ) : null}
              </Center>
            </Flex>
          </Box>

          {children}
        </Box>
      </Element>
    );
  };

  const AdminComment = ({ children, isVisible }) =>
    isVisible ? (
      <Box mt={10}>
        <Text textDecoration="underline" mb={2} fontWeight="bold" fontSize="sm">
          Commentaire de l'école&nbsp;:
        </Text>
        <Box>
          <Box
            bg={`linear-gradient(270deg,rgba(255,255,255,0.48) 0%,rgba(255,255,255,0.221538) 53.85%,rgba(255,255,255,0) 100%),rgba(253,255,148,0.69)`}
            display="inline-block"
          >
            {children}
          </Box>
        </Box>
      </Box>
    ) : null;

  const aLevelYearsList = [];

  const currentYear = new Date().getFullYear();
  for (let index = currentYear; index >= currentYear - 50; index--) {
    aLevelYearsList.push(
      <option value={parseInt(index)} key={index}>
        {index}
      </option>
    );
  }

  return (
    <Formik
      initialValues={{
        paymentScheduleFile:
          settings.paymentScheduleFile && settings.paymentScheduleFile.value
            ? settings.paymentScheduleFile.value
            : '',
        paymentOption:
          settings.paymentOption && settings.paymentOption.value
            ? settings.paymentOption.value
            : '',
        paymentAlumni:
          settings.paymentAlumni && settings.paymentAlumni.value
            ? settings.paymentAlumni.value
            : '',
        paymentLevelUpgrade:
          settings.paymentLevelUpgrade && settings.paymentLevelUpgrade.value
            ? settings.paymentLevelUpgrade.value
            : '',
        ineNumber:
          settings.ineNumber && settings.ineNumber.value
            ? settings.ineNumber.value
            : '',
        cvecNumber:
          settings.cvecNumber && settings.cvecNumber.value
            ? settings.cvecNumber.value
            : '',
        cvecFile:
          settings.cvecFile && settings.cvecFile.value
            ? settings.cvecFile.value
            : '',
        aLevelYear:
          settings.aLevelYear && settings.aLevelYear.value
            ? parseInt(settings.aLevelYear.value)
            : '',
        aLevelSpeciality:
          settings.aLevelSpeciality && settings.aLevelSpeciality.value
            ? parseInt(settings.aLevelSpeciality.value)
            : '',
        aLevelHighSchoolInFrance: settings.aLevelHighSchoolInFrance
          ? settings.aLevelHighSchoolInFrance.value
          : true,
        aLevelHighSchool:
          settings.aLevelHighSchool && settings.aLevelHighSchool.value
            ? parseInt(settings.aLevelHighSchool.value)
            : settings.aLevelHighSchoolInFrance === false
            ? -1
            : null,
        aLevelHighSchoolCustom:
          settings.aLevelHighSchoolCustom &&
          settings.aLevelHighSchoolCustom.value
            ? settings.aLevelHighSchoolCustom.value
            : '',
        aLevelResultsFile:
          settings.aLevelResultsFile && settings.aLevelResultsFile.value
            ? settings.aLevelResultsFile.value
            : '',
        lastDiplomaFile:
          settings.lastDiplomaFile && settings.lastDiplomaFile.value
            ? settings.lastDiplomaFile.value
            : '',
        lastDiplomaLevel:
          settings.lastDiplomaLevel && settings.lastDiplomaLevel.value
            ? settings.lastDiplomaLevel.value
            : '',
        lastDiplomaLevelFreeText:
          settings.lastDiplomaLevelFreeText &&
          settings.lastDiplomaLevelFreeText.value
            ? settings.lastDiplomaLevelFreeText.value
            : '',
        socialSecurityNumber:
          settings.socialSecurityNumber && settings.socialSecurityNumber.value
            ? settings.socialSecurityNumber.value
            : '',
        insuranceCertificateFile:
          settings.insuranceCertificateFile &&
          settings.insuranceCertificateFile.value
            ? settings.insuranceCertificateFile.value
            : '',
        apprenticeNumber:
          settings.apprenticeNumber && settings.apprenticeNumber.value
            ? settings.apprenticeNumber.value
            : '',
        curriculumVitaeFile:
          settings.curriculumVitaeFile && settings.curriculumVitaeFile.value
            ? settings.curriculumVitaeFile.value
            : '',
        linkedInProfileUrl:
          settings.linkedInProfileUrl && settings.linkedInProfileUrl.value
            ? settings.linkedInProfileUrl.value
            : '',
        rqthFile:
          settings.rqthFile && settings.rqthFile.value
            ? settings.rqthFile.value
            : '',
        healthSpecificNeeds:
          settings.healthSpecificNeeds && settings.healthSpecificNeeds.value
            ? settings.healthSpecificNeeds.value
            : '',
        ribIban:
          settings.ribIban && settings.ribIban.value
            ? settings.ribIban.value
            : '',

        ribBic:
          settings.ribBic && settings.ribBic.value ? settings.ribBic.value : '',
        ribBankAddress:
          settings.ribBankAddress && settings.ribBankAddress.value
            ? settings.ribBankAddress.value
            : '',

        aLevelSpecialityOptions:
          settings.aLevelSpecialityOptions.value &&
          typeof settings.aLevelSpecialityOptions.value === 'object'
            ? settings.aLevelSpecialityOptions.value
            : [0, 0],
      }}
      validateOnChange
      validationSchema={yup.object().shape({
        // ineNumber: yup
        //     .string()
        //     .matches(
        //         "^([0-9]{10}[A-Za-z]{1})|([0-9]{9}[A-Za-z]{2})$",
        //         "Votre INE doit contenir 10 chiffres et une ou deux lettres"
        //     )
        //     .nullable()
        // ,

        // socialSecurityNumber: yup
        //     .string('Must be a string')
        //     .test(
        //         'SSN-Pattern',
        //         'Your social security number seems to be incorrect',
        //         val => val && SSN.validate(val)
        //     )
        // ,
        aLevelYear: yup.number('Must be a number').nullable(),
        aLevelSpeciality: yup.number('Must be a number').nullable(),
        aLevelHighSchool: yup.number('Must be a number').nullable(),
        // ribIban: yup.number('Le numéro IBAN doit être un nombre')
        ribIban: yup
          .string()
          .nullable()
          .test(
            'ribIban',
            `La syntaxe de votre IBAN semble incorrecte`,
            value => {
              if (!value) return true;
              // console.log('value IBAN', value, IBAN.isValid(value))
              return IBAN.isValid(value);
              // return value >= 18 && value <= 65;
            }
          ),
        // paymentAlumni: yup
        //     .boolean('Must be a boolean')
        //     .nullable()
        // ,
        // cvecNumber: yup
        //     .string()
        //     .test('len', 'Your CVEC number is a 12 alphanumeric string', val => val && val.length === 12),
      })}
      onSubmit={(values, actions) => {
        // console.log(values)
        // console.log('submit')
        axios
          .patch(
            `${process.env.REACT_APP_API_URL}/inscription_biel.php/student/extendedprofile/${studentId}`,
            values
          )
          .then(() => {
            actions.setSubmitting(false);
            setHasUpdates(false);
            toast({
              title: 'Votre profil a été sauvegardé !',
              description:
                'Vous pouvez poursuivre la saisie de vos informations sans oublier de finir par un nouvel enregistrement.',
              status: 'success',
              duration: 5000,
              isClosable: true,
            });
            // this.props.redirectToPayment()
          });
      }}
    >
      {({
        values,
        errors,
        touched,
        setFieldValue,
        setFieldTouched,
        isSubmitting,
        isValid,
        handleChange,
        handleBlur,
      }) => (
        <Form>
          <UpdateButton
            inProp={hasUpdates && Object.entries(errors).length === 0}
            isSubmitting={isSubmitting}
          />
          {/* <Debug data={school} /> */}
          <Stack>
            {school.code !== 'asf' && (
              <IconTextBox icon={<FaInfo />}>
                <VStack spacing={'2'} alignItems="flex-start">
                  <Text
                    textTransform="uppercase"
                    fontWeight={700}
                    fontSize="sm"
                    letterSpacing="wide"
                  >
                    Votre Inscription est validée !
                  </Text>
                  <Text>
                    Votre inscription est confirmée pour l'année{' '}
                    <Text as="span" fontWeight="bold">
                      {settings.teachingPeriod}
                    </Text>
                    , sous réserve de la validation de votre année en cours.
                  </Text>
                </VStack>
              </IconTextBox>
            )}

            {/* <Debug data={{ "hasUpdates": hasUpdates, "errors": errors }} /> */}
            {/* <Debug data={settings.paymentOptions} /> */}

            {settings.paymentOptions.isPaymentAppEnable && (
              <ScheduleAppInfos
                paymentOptions={settings.paymentOptions}
                student={settings.student}
                school={settings.school}
                data={settings}
              />
            )}

            {settings.paymentScheduleFile.isEnabled ? (
              <Section>
                <SectionTitle>Échéancier</SectionTitle>
                <FieldBlock
                  isValid={
                    touched.paymentScheduleFile
                      ? null
                      : settings.paymentScheduleFile.isValid
                  }
                >
                  <Stack spacing={4}>
                    <Box>
                      <SectionLegend>
                        Veuillez télécharger l’échéancier ci-dessus, puis
                        remplissez-le. Vous pouvez nous retourner votre choix
                        d’échéancier directement en le joignant ci-dessous.
                      </SectionLegend>
                      <Button
                        // variant='link'
                        as="a"
                        href={settings.scheduleFileUrl}
                        target="_blank"
                        size="sm"
                        mt={3}
                      >
                        Échéancier à télécharger
                        <ArrowForwardIcon ml={1} />
                      </Button>
                    </Box>
                    <FormControl mt={2} isInvalid={false}>
                      <FormLabel>Envoyez votre échéancier</FormLabel>
                      <BrowseAndJoinDocument
                        fieldName={`paymentScheduleFile`}
                        uploadEndpoint="/inscription_biel.php/student/attach/"
                        value={values.paymentScheduleFile}
                        isDisabled={settings.paymentScheduleFile.isValid}
                        userId={studentId}
                        previewUrl={settings.paymentScheduleFile}
                        setFieldValue={value => {
                          setFieldTouched('paymentScheduleFile', true, true);
                          setFieldValue('paymentScheduleFile', value);
                        }}
                      />
                      <Helper
                        label={settings.paymentScheduleFile.helpLabel}
                        pictureFileUrl={
                          settings.paymentScheduleFile.helpPictureFileUrl
                        }
                      />
                      <FormErrorMessage>
                        Votre document n'est pas lisible
                      </FormErrorMessage>
                      <AdminComment
                        isVisible={
                          settings.paymentScheduleFile.isValid === false &&
                          settings.paymentScheduleFile.comment !== ''
                        }
                      >
                        {settings.paymentScheduleFile.comment}
                      </AdminComment>
                    </FormControl>

                    {settings.paymentOption.isEnabled ? (
                      <Field name="paymentOption">
                        {({ form, field }) => (
                          <FormControl mt={2} isInvalid={false}>
                            <FormLabel>Modalité de paiement</FormLabel>
                            <Select
                              {...field}
                              onChange={e => {
                                setFieldValue(field.name, e.target.value);
                                setHasUpdates(true);
                              }}
                              bgColor="white"
                              isDisabled={settings.paymentScheduleFile.isValid}
                              placeholder="Choisissez"
                            >
                              <option value={'1x'}>En 1 fois</option>
                              <option value={'3x'}>En 3 fois</option>
                              <option value={'6x'}>En 6 fois</option>
                            </Select>
                            <Helper
                              label={settings.paymentOption.helpLabel}
                              pictureFileUrl={
                                settings.paymentOption.helpPictureFileUrl
                              }
                            />

                            <AdminComment
                              isVisible={
                                settings.paymentOption.isValid === false &&
                                settings.paymentOption.comment !== ''
                              }
                            >
                              {settings.paymentOption.comment}
                            </AdminComment>
                            <Debug data={values} />
                          </FormControl>
                        )}
                      </Field>
                    ) : null}

                    <Divider />

                    {settings.paymentAlumni.isEnabled ? (
                      <Field name="paymentAlumni">
                        {({ form, field }) => (
                          <FormControl mt={2} isInvalid={false}>
                            <FormLabel>
                              Adhésion à la plateforme alumni
                            </FormLabel>
                            <Select
                              onChange={e => {
                                setFieldValue(field.name, e.target.value);
                                setHasUpdates(true);
                              }}
                              bgColor="white"
                              isDisabled={settings.paymentScheduleFile.isValid}
                              value={field.value}
                              placeholder="Choisissez"
                            >
                              <AlumniFormulas
                                schoolCode={settings.school.code}
                              />
                              {/* <option value={2}>{`${settings.school.alumniPlatformName} Plus`}</option>
                                                                <option value={1}>{`${settings.school.alumniPlatformName}`}</option>
                                                                <option value={0}>{`Je ne souhaite pas adhérer au réseau alumni ${settings.school.alumniPlatformName}`}</option> */}
                            </Select>
                            <Helper
                              label={settings.paymentAlumni.helpLabel}
                              pictureFileUrl={
                                settings.paymentAlumni.helpPictureFileUrl
                              }
                            />

                            <AdminComment
                              isVisible={
                                settings.paymentAlumni.isValid === false &&
                                settings.paymentAlumni.comment !== ''
                              }
                            >
                              {settings.paymentAlumni.comment}
                            </AdminComment>
                          </FormControl>
                        )}
                      </Field>
                    ) : null}

                    {settings.paymentLevelUpgrade.isEnabled ? (
                      <Field name="paymentLevelUpgrade">
                        {({ form, field }) => (
                          <FormControl mt={2} isInvalid={false}>
                            <FormLabel>
                              Inscription à la remise à niveau
                            </FormLabel>
                            <Select
                              {...field}
                              placeholder="Choisissez"
                              bgColor="white"
                              isDisabled={settings.paymentScheduleFile.isValid}
                            >
                              <option value={'formule1'}>Formule 1</option>
                              <option value={'formule2'}>Formule 2</option>
                              <option value={'formule3'}>Formule 3</option>
                            </Select>
                            <Helper
                              label={settings.paymentLevelUpgrade.helpLabel}
                              pictureFileUrl={
                                settings.paymentLevelUpgrade.helpPictureFileUrl
                              }
                            />

                            <AdminComment
                              isVisible={
                                settings.paymentLevelUpgrade.isValid ===
                                  false &&
                                settings.paymentLevelUpgrade.comment !== ''
                              }
                            >
                              {settings.paymentLevelUpgrade.comment}
                            </AdminComment>
                          </FormControl>
                        )}
                      </Field>
                    ) : null}

                    {settings.school.paymentInformationsData.isShown ? (
                      <SchoolBankInformations
                        {...settings.school.paymentInformationsData}
                      />
                    ) : null}
                  </Stack>
                </FieldBlock>
              </Section>
            ) : null}

            {settings.ineNumber.isEnabled ? (
              <Section id="ine">
                <SectionTitle>
                  Identifiant National Étudiant unique
                </SectionTitle>
                <SectionLegend>
                  Le N° INE est un Identifiant National Etudiant unique. Vous
                  trouverez ce N° sur le relevé de notes du BACCALAUREAT
                  FRANÇAIS. Il peut également se trouver sur un certificat de
                  scolarité, un relevé de notes ou à défaut, sur une ancienne
                  carte d'étudiant. Ce numéro est composé de 11 caractères.
                </SectionLegend>
                <Field name="ineNumber">
                  {({ form, field }) => (
                    <FieldBlock
                      isValid={
                        touched.ineNumber ? null : settings.ineNumber.isValid
                      }
                    >
                      <FormControl
                        isInvalid={errors.ineNumber && touched.ineNumber}
                      >
                        <FormLabel>Votre n° INE</FormLabel>
                        <Input
                          {...field}
                          isDisabled={settings.ineNumber.isValid}
                          bg="white"
                          type="text"
                          maxLength="11"
                          onBlur={e => {
                            field.onBlur(e);
                            // form.submitForm();
                            setHasUpdates(true);
                          }}
                        />
                        <FormErrorMessage>{errors.ineNumber}</FormErrorMessage>

                        <Helper
                          label={settings.ineNumber.helpLabel}
                          pictureFileUrl={settings.ineNumber.helpPictureFileUrl}
                        />
                      </FormControl>
                      <AdminComment
                        isVisible={
                          settings.ineNumber.isValid === false &&
                          settings.ineNumber.comment !== ''
                        }
                      >
                        {settings.ineNumber.comment}
                      </AdminComment>
                    </FieldBlock>
                  )}
                </Field>
              </Section>
            ) : null}

            {settings.cvecNumber.isEnabled && settings.cvecFile.isEnabled ? (
              <Section id="cvec">
                <SectionTitle>
                  Contribution de vie étudiante et de campus
                </SectionTitle>
                <SectionLegend>
                  <Text as="p" mb={{ base: 4, lg: 6 }}>
                    Les étudiants en formation initiale d’enseignement supérieur
                    ainsi qu’en contrat d’apprentissage doivent s’acquitter
                    d’une contribution annuelle dédiée à la vie étudiant et de
                    campus (CVEC), comme prévu par la loi Orientation et
                    Réussite des Étudiants (article L814-5-1).
                  </Text>
                  <Text fontWeight={600} mb={{ base: 4, lg: 6 }}>
                    Cette contribution est à régler auprès du CROUS, sur le site{' '}
                    <ChakraLink
                      href="https://cvec.etudiant.gouv.fr/"
                      target="_blank"
                      color="brand.1.600"
                      textDecoration="underline"
                    >
                      https://cvec.etudiant.gouv.fr/
                    </ChakraLink>
                    .
                  </Text>
                  <Text as="p">
                    Une fois votre contribution payée pour l’année{' '}
                    {settings.teachingPeriod}, vous aurez une attestation
                    contenant le numéro à nous retourner, à l’aide des champs
                    ci-dessous.
                    <br />
                    Si vous êtes dispensé de cotisation CVEC, vous pouvez nous
                    retourner le document de dispense..{' '}
                  </Text>
                </SectionLegend>
                <Field name="cvecNumber">
                  {({ form, field, handleChange }) => (
                    <FieldBlock
                      isValid={
                        touched.cvecNumber ? null : settings.cvecNumber.isValid
                      }
                    >
                      <FormControl
                        as={Stack}
                        spacing={2}
                        isInvalid={errors.cvecNumber && touched.cvecNumber}
                      >
                        <FormLabel>N° de CVEC&nbsp;:</FormLabel>
                        <Input
                          {...field}
                          type="text"
                          isDisabled={settings.cvecNumber.isValid}
                          onBlur={e => {
                            field.onBlur(e);
                            setHasUpdates(true);
                          }}
                          bg="white"
                        />
                        <FormErrorMessage>
                          Votre numéro CVEC doit comprendre 12 caractères.
                        </FormErrorMessage>
                        <Text fontWeight={600} fontSize="sm">
                          <Text as="span" color="brand.1.500">
                            ATTENTION
                          </Text>{' '}
                          - Assurez-vous de payer votre cotisation pour l’année
                          prochaine ({settings.teachingPeriod}) !
                        </Text>
                        <FormHelperText fontSize="sm">
                          En cas de paiement pour la mauvaise année, vous devrez
                          demander directement sur cette plateforme le
                          remboursement, l’école ne perçoit jamais votre
                          cotisation CVEC.
                          <br />
                          Section "Être remboursé":{' '}
                          <a
                            href="https://cvec.etudiant.gouv.fr/"
                            target="_blank"
                            style={{ textDecoration: 'underline' }}
                          >
                            https://cvec.etudiant.gouv.fr/
                          </a>
                        </FormHelperText>
                        {/* <Helper
                          label={settings.cvecNumber.helpLabel}
                          pictureFileUrl={
                            settings.cvecNumber.helpPictureFileUrl
                          }
                        /> */}
                      </FormControl>
                      <AdminComment
                        isVisible={
                          settings.cvecNumber.isValid === false &&
                          settings.cvecNumber.comment !== ''
                        }
                      >
                        {settings.cvecNumber.comment}
                      </AdminComment>
                    </FieldBlock>
                  )}
                </Field>
                <FieldBlock
                  isValid={touched.cvecFile ? null : settings.cvecFile.isValid}
                >
                  <FormControl mt={2} isInvalid={false}>
                    <FormLabel>Document attestation CVEC</FormLabel>
                    <BrowseAndJoinDocument
                      fieldName={`cvecFile`}
                      uploadEndpoint="/inscription_biel.php/student/attach/"
                      value={values.cvecFile}
                      previewUrl={settings.cvecFile}
                      isDisabled={settings.cvecFile.isValid}
                      userId={studentId}
                      setFieldValue={value => {
                        setFieldTouched('cvecFile', true, true);
                        setFieldValue('cvecFile', value);
                      }}
                    />
                    <Helper
                      label={settings.cvecFile.helpLabel}
                      pictureFileUrl={settings.cvecFile.helpPictureFileUrl}
                    />
                    <FormErrorMessage>
                      Votre document n'est pas lisible
                    </FormErrorMessage>
                    <AdminComment
                      isVisible={
                        settings.cvecFile.isValid === false &&
                        settings.cvecFile.comment !== ''
                      }
                    >
                      {settings.cvecFile.comment}
                    </AdminComment>
                  </FormControl>
                </FieldBlock>
              </Section>
            ) : null}

            {settings.aLevelYear.isEnabled ||
            settings.aLevelHighSchool.isEnabled ||
            settings.aLevelSpeciality.isEnabled ||
            settings.aLevelResultsFile.isEnabled ? (
              <Section id="aLevel">
                <SectionTitle>À propos de votre baccalauréat </SectionTitle>

                {settings.aLevelYear.isEnabled ? (
                  <Field name="aLevelYear">
                    {({ form, field, handleChange }) => (
                      <FieldBlock
                        isValid={
                          touched.aLevelYear
                            ? null
                            : settings.aLevelYear.isValid
                        }
                      >
                        <FormControl>
                          <FormLabel>
                            En quelle année avez-vous obtenu le baccalauréat ?
                          </FormLabel>
                          <Select
                            {...field}
                            bg="white"
                            onChange={e => {
                              setFieldTouched(field.name, true, true);
                              setFieldValue(field.name, e.target.value);

                              setFieldValue(`aLevelSpeciality`, null);
                              setFieldValue(`aLevelSpecialityOptions`, null);

                              setHasUpdates(true);
                            }}
                            onBlur={e => {
                              field.onBlur(e);
                              setHasUpdates(true);
                            }}
                            placeholder={`Sélectionnez l'année d'obtention de votre bac`}
                            value={values.aLevelYear ? values.aLevelYear : ''}
                            isDisabled={settings.aLevelYear.isValid}
                          >
                            {aLevelYearsList}
                          </Select>
                          <FormErrorMessage>
                            La valeur semble incorrecte
                          </FormErrorMessage>
                          <Helper
                            label={settings.aLevelYear.helpLabel}
                            pictureFileUrl={
                              settings.aLevelYear.helpPictureFileUrl
                            }
                          />
                        </FormControl>
                        <AdminComment
                          isVisible={
                            settings.aLevelYear.isValid === false &&
                            settings.aLevelYear.comment !== ''
                          }
                        >
                          {settings.aLevelYear.comment}
                        </AdminComment>
                      </FieldBlock>
                    )}
                  </Field>
                ) : null}

                {settings.aLevelSpeciality.isEnabled && values.aLevelYear ? (
                  <Field name="aLevelSpeciality">
                    {({ form, field, handleChange }) => (
                      <FieldBlock
                        isValid={
                          touched.aLevelSpeciality
                            ? null
                            : settings.aLevelSpeciality.isValid
                        }
                      >
                        <FormControl mt={2}>
                          <FormLabel>
                            Quelle est la fillière de votre baccalauréat ?
                          </FormLabel>
                          {/* <ALevelSpeciality
                                                        setFieldValue={(value) => {
                                                            setFieldValue('aLevelSpeciality', parseInt(value));
                                                            setFieldTouched('aLevelSpeciality', true, true);
                                                            setHasUpdates(true);
                                                        }}
                                                        isDisabled={settings.aLevelSpeciality.isValid}
                                                        value={values.aLevelSpeciality}
                                                    /> */}

                          <ALevelSpecialityWithOptions
                            setFieldValue={(name, value) => {
                              setFieldValue(name, value);
                              setFieldTouched(name, true, true);
                              setHasUpdates(true);
                            }}
                            isDisabled={settings.aLevelSpeciality.isValid}
                            value={values.aLevelSpeciality}
                            values={values}
                            setHasUpdates={setHasUpdates}
                          />
                          <FormErrorMessage>
                            Cette valeur semble incorrecte
                          </FormErrorMessage>
                          <Helper
                            label={settings.aLevelSpeciality.helpLabel}
                            pictureFileUrl={
                              settings.aLevelSpeciality.helpPictureFileUrl
                            }
                          />
                        </FormControl>
                        <AdminComment
                          isVisible={
                            settings.aLevelSpeciality.isValid === false &&
                            settings.aLevelSpeciality.comment !== ''
                          }
                        >
                          {settings.aLevelSpeciality.comment}
                        </AdminComment>
                      </FieldBlock>
                    )}
                  </Field>
                ) : null}

                {settings.aLevelResultsFile.isEnabled ? (
                  <FormControl mt={2}>
                    <FieldBlock
                      isValid={
                        touched.aLevelResultsFile
                          ? null
                          : settings.aLevelResultsFile.isValid
                      }
                    >
                      <FormLabel>{settings.aLevelResultsFile.label}</FormLabel>
                      <FormHelperText mb={3}>
                        Veuillez joindre ci-dessous votre diplôme du
                        baccalauréat. Si vous ne l’avez pas encore obtenu,
                        pensez à revenir le déposer dans cet espace
                        d'inscription en juillet, dès votre bac en poche !
                      </FormHelperText>
                      <BrowseAndJoinDocument
                        userId={studentId}
                        uploadEndpoint="/inscription_biel.php/student/attach/"
                        fieldName={`aLevelResultsFile`}
                        value={values.aLevelResultsFile}
                        previewUrl={settings.aLevelResultsFile}
                        isDisabled={settings.aLevelResultsFile.isValid}
                        setFieldValue={value => {
                          setFieldTouched('aLevelResultsFile', true, true);
                          setFieldValue('aLevelResultsFile', value);
                        }}
                      />
                      <Helper
                        label={settings.aLevelResultsFile.helpLabel}
                        pictureFileUrl={
                          settings.aLevelResultsFile.helpPictureFileUrl
                        }
                      />
                      <AdminComment
                        isVisible={
                          settings.aLevelResultsFile.isValid === false &&
                          settings.aLevelResultsFile.comment !== ''
                        }
                      >
                        {settings.aLevelResultsFile.comment}
                      </AdminComment>
                    </FieldBlock>
                  </FormControl>
                ) : null}
              </Section>
            ) : null}
            {settings.aLevelHighSchool.isEnabled ? (
              <FieldBlock
                isValid={
                  touched.aLevelHighSchool
                    ? null
                    : settings.aLevelHighSchool.isValid ||
                      settings.aLevelHighSchoolCustom.isValid
                }
              >
                <Stack spacing={4}>
                  <Stack>
                    <Text>
                      Avez-vous réalisé votre dernière année de lycée dans un
                      établissement en France (métropole ou DOM-TOM)&nbsp;?
                    </Text>
                    <ButtonGroup size="sm">
                      <Button
                        colorScheme={
                          values.aLevelHighSchoolInFrance ? `blue` : undefined
                        }
                        onClick={() => {
                          setFieldValue(`aLevelHighSchoolInFrance`, true);
                          setFieldValue(`aLevelHighSchoolCustom`, ``);
                        }}
                      >
                        Oui
                      </Button>
                      <Button
                        colorScheme={
                          !values.aLevelHighSchoolInFrance ? `blue` : undefined
                        }
                        onClick={() => {
                          setFieldValue(`aLevelHighSchoolInFrance`, false);
                          setFieldValue(`aLevelHighSchool`, -1);
                        }}
                      >
                        Non
                      </Button>
                    </ButtonGroup>
                  </Stack>

                  {!values.aLevelHighSchoolInFrance ? (
                    <Field name="aLevelHighSchoolCustom">
                      {({ form, field, handleChange }) => (
                        <Box>
                          <FormLabel>
                            {settings.aLevelHighSchoolCustom.label}
                          </FormLabel>
                          <Input
                            {...field}
                            type="text"
                            isDisabled={settings.aLevelHighSchoolCustom.isValid}
                            onBlur={e => {
                              field.onBlur(e);
                              setHasUpdates(true);
                            }}
                          />
                          <Helper
                            label={settings.aLevelHighSchoolCustom.helpLabel}
                            pictureFileUrl={
                              settings.aLevelHighSchoolCustom.helpPictureFileUrl
                            }
                          />
                        </Box>
                      )}
                    </Field>
                  ) : !values.aLevelHighSchool ||
                    values.aLevelHighSchool === -1 ? (
                    <HighSchoolSelector
                      setFieldValue={value => {
                        setFieldValue('aLevelHighSchool', parseInt(value));
                        setFieldTouched('aLevelHighSchool', value);
                        setHasUpdates(true);
                      }}
                    />
                  ) : (
                    <HighSchoolLabel
                      schoolId={values.aLevelHighSchool}
                      deleteValue={() =>
                        setFieldValue('aLevelHighSchool', undefined)
                      }
                      isDisabled={settings.aLevelHighSchool.isValid}
                    />
                  )}
                </Stack>

                <Helper
                  label={settings.aLevelHighSchool.helpLabel}
                  pictureFileUrl={settings.aLevelHighSchool.helpPictureFileUrl}
                />
                <AdminComment
                  isVisible={
                    settings.aLevelHighSchool.isValid === false &&
                    settings.aLevelHighSchool.comment !== ''
                  }
                >
                  {settings.aLevelHighSchool.comment}
                </AdminComment>
              </FieldBlock>
            ) : null}

            {settings.lastDiplomaFile.isEnabled ? (
              <Section id="lastDiploma">
                <SectionTitle>Dernier diplôme obtenu</SectionTitle>
                <FieldBlock
                  isValid={
                    touched.lastDiplomaFile
                      ? null
                      : settings.lastDiplomaFile.isValid
                  }
                >
                  <Stack spacing={{ base: 4, lg: 6 }}>
                    <FormControl mt={2} isInvalid={false}>
                      <FormLabel>Dernier diplôme obtenu :</FormLabel>

                      <FormHelperText mb={3}>
                        Veuillez renseigner ici votre dernier diplôme validé,
                        par exemple votre Bachelor, BTS …
                        {/* Il est préférable de
                        mettre le diplôme plutôt que les notes, sauf si
                        celles-ci mentionnent la validation. */}
                      </FormHelperText>
                    </FormControl>

                    <Field name="lastDiplomaLevel">
                      {({ form, field, handleChange }) => (
                        <Box>
                          <FormLabel>
                            Quel est votre dernier diplôme/titre obtenu:
                          </FormLabel>
                          <LastDiplomaFieldBlock
                            field={field}
                            setHasUpdates={() => setHasUpdates(true)}
                          />
                          {/* <Debug data={field} /> */}
                        </Box>
                      )}
                    </Field>

                    <Field name="lastDiplomaLevelFreeText">
                      {({ form, field, handleChange }) => (
                        <>
                          <FormControl
                            isInvalid={
                              errors.lastDiplomaLevelFreeText &&
                              touched.lastDiplomaLevelFreeText
                            }
                          >
                            <FormLabel>
                              Veuillez indiquer l'intitulé précis du dernier
                              diplôme/titre obtenu :
                            </FormLabel>
                            <Input
                              {...field}
                              type="text"
                              bg="white"
                              isDisabled={
                                settings.lastDiplomaLevelFreeText.isValid
                              }
                              onBlur={e => {
                                field.onBlur(e);
                                // form.submitForm();
                                setHasUpdates(true);
                              }}
                            />
                            <Helper
                              label={
                                settings.lastDiplomaLevelFreeText.helpLabel
                              }
                              pictureFileUrl={
                                settings.lastDiplomaLevelFreeText
                                  .helpPictureFileUrl
                              }
                            />
                          </FormControl>
                          <AdminComment
                            isVisible={
                              settings.lastDiplomaLevelFreeText.isValid ===
                                false &&
                              settings.lastDiplomaLevelFreeText.comment !== ''
                            }
                          >
                            {settings.lastDiplomaLevelFreeText.comment}
                          </AdminComment>
                        </>
                      )}
                    </Field>

                    <FormControl>
                      <FormLabel>
                        Envoyez la copie de votre dernier diplôme :
                      </FormLabel>
                      <BrowseAndJoinDocument
                        userId={studentId}
                        uploadEndpoint="/inscription_biel.php/student/attach/"
                        fieldName={`lastDiplomaFile`}
                        value={values.lastDiplomaFile}
                        previewUrl={settings.lastDiplomaFile}
                        isDisabled={settings.lastDiplomaFile.isValid}
                        setFieldValue={value => {
                          setFieldTouched('lastDiplomaFile', true, true);
                          setFieldValue('lastDiplomaFile', value);
                        }}
                      />
                      <FormHelperText>
                        Il est préférable de mettre le diplôme plutôt que les
                        notes, sauf si celles-ci mentionnent la validation.
                      </FormHelperText>
                    </FormControl>

                    <Helper
                      label={settings.lastDiplomaFile.helpLabel}
                      pictureFileUrl={
                        settings.lastDiplomaFile.helpPictureFileUrl
                      }
                    />
                    <AdminComment
                      isVisible={
                        settings.lastDiplomaFile.isValid === false &&
                        settings.lastDiplomaFile.comment !== ''
                      }
                    >
                      {settings.lastDiplomaFile.comment}
                    </AdminComment>
                  </Stack>
                </FieldBlock>
              </Section>
            ) : null}

            <Section id="insurance">
              <SectionTitle>Assurances</SectionTitle>

              {settings.socialSecurityNumber &&
              settings.socialSecurityNumber.isEnabled ? (
                <Field name="socialSecurityNumber">
                  {({ form, field, handleChange }) => (
                    <FieldBlock
                      isValid={
                        touched.socialSecurityNumber
                          ? null
                          : settings.socialSecurityNumber.isValid
                      }
                    >
                      <FormControl
                        isInvalid={
                          errors.socialSecurityNumber &&
                          touched.socialSecurityNumber
                        }
                      >
                        <FormLabel>
                          Veuillez mettre votre numéro de Sécurité Sociale, sans
                          espaces.
                        </FormLabel>
                        <Input
                          {...field}
                          type="text"
                          bg="white"
                          isDisabled={settings.socialSecurityNumber.isValid}
                          onBlur={e => {
                            field.onBlur(e);
                            // form.submitForm();
                            setHasUpdates(true);
                          }}
                        />
                        <Helper
                          label={settings.socialSecurityNumber.helpLabel}
                          pictureFileUrl={
                            settings.socialSecurityNumber.helpPictureFileUrl
                          }
                        />
                      </FormControl>
                      <AdminComment
                        isVisible={
                          settings.socialSecurityNumber.isValid === false &&
                          settings.socialSecurityNumber.comment !== ''
                        }
                      >
                        {settings.socialSecurityNumber.comment}
                      </AdminComment>
                    </FieldBlock>
                  )}
                </Field>
              ) : null}

              {settings.insuranceCertificateFile &&
              settings.insuranceCertificateFile.isEnabled ? (
                <FieldBlock
                  isValid={
                    touched.insuranceCertificateFile
                      ? null
                      : settings.insuranceCertificateFile.isValid
                  }
                >
                  <FormControl mt={2} isInvalid={false}>
                    <FormLabel>
                      Certificat d'assurance responsabilité civile :
                    </FormLabel>
                    <FormHelperText mb={3}>
                      Ce document est à demander à votre assurance, ou bien si
                      vous habitez chez vos parents, la responsabilité civile
                      (RC) est en général incluse dans leur contrat habitation,
                      voire assurance automobile, vos parents peuvent vous la
                      fournir dans ce cas.
                    </FormHelperText>
                    <BrowseAndJoinDocument
                      userId={studentId}
                      uploadEndpoint="/inscription_biel.php/student/attach/"
                      fieldName={`insuranceCertificateFile`}
                      value={values.insuranceCertificateFile}
                      previewUrl={settings.insuranceCertificateFile}
                      isDisabled={settings.insuranceCertificateFile.isValid}
                      setFieldValue={value => {
                        setFieldTouched('insuranceCertificateFile', true, true);
                        setFieldValue('insuranceCertificateFile', value);
                      }}
                    />
                    <Helper
                      label={settings.socialSecurityNumber.helpLabel}
                      pictureFileUrl={
                        settings.socialSecurityNumber.helpPictureFileUrl
                      }
                    />
                  </FormControl>
                  <AdminComment
                    isVisible={
                      settings.insuranceCertificateFile.isValid === false &&
                      settings.insuranceCertificateFile.comment !== ''
                    }
                  >
                    {settings.insuranceCertificateFile.comment}
                  </AdminComment>
                </FieldBlock>
              ) : null}
            </Section>

            {settings.apprenticeNumber &&
            settings.apprenticeNumber.isEnabled ? (
              <Section id="apprentice">
                <SectionTitle>Apprentissage</SectionTitle>
                <Field name="apprenticeNumber">
                  {({ form, field, handleChange }) => (
                    <FieldBlock
                      isValid={
                        touched.apprenticeNumber
                          ? null
                          : settings.apprenticeNumber.isValid
                      }
                    >
                      <FormControl>
                        <FormLabel>Numéro d'apprenti</FormLabel>
                        <Input
                          {...field}
                          type="text"
                          isDisabled={settings.apprenticeNumber.isValid}
                          onBlur={e => {
                            field.onBlur(e);
                            setHasUpdates(true);
                          }}
                          bg="white"
                        />
                        <Helper label={settings.apprenticeNumber.helpLabel} />
                        <Helper
                          label={settings.apprenticeNumber.helpPictureFileLabel}
                          pictureFileUrl={
                            settings.apprenticeNumber.helpPictureFileUrl
                          }
                        />
                      </FormControl>
                      <AdminComment
                        isVisible={
                          settings.apprenticeNumber.isValid === false &&
                          settings.apprenticeNumber.comment !== ''
                        }
                      >
                        {settings.apprenticeNumber.comment}
                      </AdminComment>
                    </FieldBlock>
                  )}
                </Field>
              </Section>
            ) : null}

            {settings.curriculumVitaeFile.isEnabled ||
            settings.linkedInProfileUrl.isEnabled ? (
              <Section id="cv">
                <SectionTitle>CV et profil LinkedIn</SectionTitle>

                <SimpleGrid columns={{ base: 1, lg: 1 }} gap={2}>
                  <FieldBlock
                    isValid={
                      touched.curriculumVitaeFile
                        ? null
                        : settings.curriculumVitaeFile.isValid
                    }
                  >
                    <FormControl mt={2} isInvalid={false}>
                      <FormLabel>Curriculum Vitae</FormLabel>
                      <BrowseAndJoinDocument
                        userId={studentId}
                        uploadEndpoint="/inscription_biel.php/student/attach/"
                        fieldName={`curriculumVitaeFile`}
                        value={values.curriculumVitaeFile}
                        previewUrl={settings.curriculumVitaeFile}
                        isDisabled={settings.curriculumVitaeFile.isValid}
                        setFieldValue={value => {
                          setFieldTouched('curriculumVitaeFile', true, true);
                          setFieldValue('curriculumVitaeFile', value);
                        }}
                      />

                      <Helper
                        label={settings.curriculumVitaeFile.helpLabel}
                        pictureFileUrl={
                          settings.curriculumVitaeFile.helpPictureFileUrl
                        }
                      />
                    </FormControl>
                    <AdminComment
                      isVisible={
                        settings.curriculumVitaeFile.isValid === false &&
                        settings.curriculumVitaeFile.comment !== ''
                      }
                    >
                      {settings.curriculumVitaeFile.comment}
                    </AdminComment>
                  </FieldBlock>
                  {settings.linkedInProfileUrl &&
                  settings.linkedInProfileUrl.isEnabled ? (
                    <Field name="linkedInProfileUrl">
                      {({ form, field, handleChange }) => (
                        <FieldBlock
                          isValid={
                            touched.linkedInProfileUrl
                              ? null
                              : settings.linkedInProfileUrl.isValid
                          }
                        >
                          <FormControl>
                            <FormLabel>Votre page LinkedIn</FormLabel>
                            <Input
                              {...field}
                              type="text"
                              isDisabled={settings.linkedInProfileUrl.isValid}
                              onBlur={e => {
                                field.onBlur(e);
                                setHasUpdates(true);
                              }}
                              bg="white"
                            />
                            <FormErrorMessage>Error message</FormErrorMessage>
                            <Helper
                              label={settings.linkedInProfileUrl.helpLabel}
                              pictureFileUrl={
                                settings.linkedInProfileUrl.helpPictureFileUrl
                              }
                            />
                          </FormControl>
                          <AdminComment
                            isVisible={
                              settings.linkedInProfileUrl.isValid === false &&
                              settings.linkedInProfileUrl.comment !== ''
                            }
                          >
                            {settings.linkedInProfileUrl.comment}
                          </AdminComment>
                        </FieldBlock>
                      )}
                    </Field>
                  ) : null}
                </SimpleGrid>
              </Section>
            ) : null}
            {settings.rqthFile.isEnabled ? (
              <Section id="rqth">
                <SectionTitle>RQTH ou Certificat médical </SectionTitle>
                <FieldBlock
                  isValid={touched.rqthFile ? null : settings.rqthFile.isValid}
                >
                  <FormControl mt={2} isInvalid={false}>
                    <FormLabel>
                      {' '}
                      {settings.rqthFile.label
                        ? settings.rqthFile.label
                        : `Document à joindre`}
                    </FormLabel>
                    <BrowseAndJoinDocument
                      userId={studentId}
                      uploadEndpoint="/inscription_biel.php/student/attach/"
                      fieldName={`rqthFile`}
                      value={values.rqthFile}
                      previewUrl={settings.curriculumVitaeFile}
                      isDisabled={settings.rqthFile.isValid}
                      setFieldValue={value => {
                        setFieldTouched('rqthFile', true, true);
                        setFieldValue('rqthFile', value);
                      }}
                    />
                    <Helper
                      label={settings.rqthFile.helpLabel}
                      pictureFileUrl={settings.rqthFile.helpPictureFileUrl}
                    />
                  </FormControl>
                  <AdminComment
                    isVisible={
                      settings.rqthFile.isValid === false &&
                      settings.rqthFile.comment !== ''
                    }
                  >
                    {settings.rqthFile.comment}
                  </AdminComment>
                </FieldBlock>
              </Section>
            ) : null}
            {settings.healthSpecificNeeds.isEnabled ? (
              <Section id="rqth">
                <SectionTitle>
                  Besoins spécifiques et aménagements de la scolarité
                </SectionTitle>
                <Field name="healthSpecificNeeds">
                  {({ form, field, handleChange }) => (
                    <FieldBlock
                      isValid={
                        touched.healthSpecificNeeds
                          ? null
                          : settings.healthSpecificNeeds.isValid
                      }
                    >
                      <FormControl mt={2} isInvalid={false}>
                        <FormLabel>
                          {settings.healthSpecificNeeds.label
                            ? settings.healthSpecificNeeds.label
                            : `Document à joindre`}
                        </FormLabel>
                        <textarea
                          style={{
                            width: `100%`,
                            minHeight: `60px`,
                            border: `solid 1px #EEEEEE`,
                            padding: `.5rem`,
                            borderRadius: `2px`,
                            fontSize: `13px`,
                          }}
                          {...field}
                          isDisabled={settings.healthSpecificNeeds.isValid}
                          onBlur={e => {
                            field.onBlur(e);
                            setHasUpdates(true);
                          }}
                          bg="white"
                        ></textarea>
                        <Helper
                          label={settings.healthSpecificNeeds.helpLabel}
                          pictureFileUrl={
                            settings.healthSpecificNeeds.helpPictureFileUrl
                          }
                        />
                      </FormControl>
                      <AdminComment
                        isVisible={
                          settings.healthSpecificNeeds.isValid === false &&
                          settings.healthSpecificNeeds.comment !== ''
                        }
                      >
                        {settings.healthSpecificNeeds.comment}
                      </AdminComment>
                    </FieldBlock>
                  )}
                </Field>
              </Section>
            ) : null}
            {settings.ribIban.isEnabled ? (
              <Section id="rib">
                <SectionTitle>Informations bancaires</SectionTitle>
                {settings.ribIban && settings.ribIban.isEnabled ? (
                  <>
                    <SectionLegend>
                      Pour les étudiants en contrat d’apprentissage, merci de
                      compléter vos coordonnées bancaires ci-dessous afin de
                      rembourser vos frais de dossier au plus vite dès la
                      réception des accords de prise en charge de votre
                      entreprise.
                    </SectionLegend>
                    <FieldBlock id="iban">
                      <FormLabel>{settings.ribIban.label}</FormLabel>
                      <RibField setHasUpdates={setHasUpdates} />
                      <Helper
                        label={settings.ribIban.helpLabel}
                        pictureFileUrl={settings.ribIban.helpPictureFileUrl}
                      />
                    </FieldBlock>
                  </>
                ) : null}

                {settings.ribFile && settings.ribFile.isEnabled ? (
                  <FieldBlock
                    isValid={touched.ribFile ? null : settings.ribFile.isValid}
                  >
                    {settings.ribFile.isEnabled ? (
                      <FormControl mt={2} isInvalid={false}>
                        <FormLabel>Votre RIB</FormLabel>

                        <FormHelperText mb={3}>
                          Merci d'envoyer une copie de votre RIB le plus à jour.
                        </FormHelperText>
                        <BrowseAndJoinDocument
                          userId={studentId}
                          uploadEndpoint="/inscription_biel.php/student/attach/"
                          fieldName={`ribFile`}
                          value={values.ribFile}
                          previewUrl={settings.ribFile}
                          isDisabled={settings.ribFile.isValid}
                          setFieldValue={value => {
                            setFieldTouched('ribFile', true, true);
                            setFieldValue('ribFile', value);
                          }}
                        />
                        {/* <Debug data={settings.ribFile} /> */}
                        <Helper
                          label={settings.ribFile.helpLabel}
                          pictureFileUrl={settings.ribFile.helpPictureFileUrl}
                        />
                      </FormControl>
                    ) : null}

                    <AdminComment
                      isVisible={
                        settings.ribFile.isValid === false &&
                        settings.ribFile.comment !== ''
                      }
                    >
                      {settings.ribFile.comment}
                    </AdminComment>
                  </FieldBlock>
                ) : null}
              </Section>
            ) : null}

            <FieldBlock>
              <Stack spacing={2}>
                <Text>Merci d’avoir complété votre profil.</Text>
                <Text>
                  S’il vous manque des informations à renseigner ou des
                  documents à charger, vous pourrez revenir sur votre profil
                  plus tard.
                </Text>
              </Stack>
            </FieldBlock>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

export default ProfileForm;
