import React from 'react';
import AdmissionFormWelcomeMessage from './AdmissionFormWelcomeMessage';

import { useIntl } from 'react-intl';
import Debug from './debug';
// import countries from '../../Utils/countries'
// import countriesClean from '../../Utils/countries-clean.js'
const Step1 = ({ values, school, diploma, campus, student }) => {
  const locale = useIntl()['locale'];
  return (
    <>
      <Debug data={school} />
      <AdmissionFormWelcomeMessage
        email={values.email}
        firstName={values.firstName}
        type={values.type}
        school={school}
        diploma={diploma}
        campus={campus}
        student={student}
        locale={locale}
      />
    </>
  );
};

export default Step1;
