import * as yup from 'yup';

export const unsubscribedFormSchema = yup.object().shape({
  reason: yup
    .string(`Must be a string`)
    .required(`Ce champs est requis`)
    .nullable(),
  detail: yup
    .string(`Must be a string`)
    .when('reason', {
      is: 'otherSchool',
      then: yup.string().required(`Ce champs est requis`),
    })
    .when('reason', {
      is: 'parcoursSupAdmitted',
      then: yup.string().required(`Ce champs est requis`),
    })
    .nullable(),
});
