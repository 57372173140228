import React from 'react';

import { Box, Link, Stack, Text } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import Debug from './Form/debug';

const Footer = ({ data, apiData, locale }) => {
  return (
    <Box
      as="footer"
      borderTop="solid 1px"
      borderTopColor="gray.100"
      color="gray.400"
      py={10}
      px={{ base: 4, lg: 0 }}
    >
      <Stack>
        {locale === 'fr' ? (
          <Text>
            Si vous rencontrez des difficultés, n’hésitez pas à utiliser le
            livechat ou à contacter directement votre campus via :{' '}
            <Link
              color="gray.500"
              textDecoration="none"
              href={`mailto:${data.school.email}`}
            >
              {data.school.email}
            </Link>{' '}
            et/ou{' '}
            <Link
              color="gray.500"
              textDecoration="none"
              href={`tel:${data.school.phone.replace(/ /g, '')}`}
            >
              {data.school.phone}
            </Link>
            .
          </Text>
        ) : (
          <Text>
            If you have any issue while filling this form, do not hesitate do
            contact us :{' '}
            <Link
              color="gray.500"
              textDecoration="none"
              href={`mailto:${data.school.email}`}
            >
              {data.school.email}
            </Link>{' '}
            and/or{' '}
            <Link
              color="gray.500"
              textDecoration="none"
              href={`tel:${data.school.phone.replace(/ /g, '')}`}
            >
              {data.school.phone}
            </Link>
            .
          </Text>
        )}
        {apiData && apiData.school.trainingPricesUrl ? (
          locale === 'fr' ? (
            <Text>
              Pour consulter les tarifs des différentes formations, vous pouvez
              les retrouver{' '}
              <Link
                textDecor="underline"
                color="gray.500"
                href={apiData.school.trainingPricesUrl}
                target="_blank"
              >
                ici
              </Link>
              .
            </Text>
          ) : (
            <Text>
              To check the prices of the various training courses, you can find
              them
              <Link
                textDecor="underline"
                color="gray.500"
                href={apiData.school.trainingPricesUrl}
                target="_blank"
              >
                here
              </Link>
              .
            </Text>
          )
        ) : null}
      </Stack>
    </Box>
  );
};

export default Footer;
