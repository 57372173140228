import React, { Component } from 'react';
import Helmet from 'react-helmet';
import StudentFunnel from './Form';
import axios from 'axios';
import FormLoading from './FormLoading';
import Layout from '../Layouts/student-funnel';
import getFavicon from '../../Utils/getFavicon';
import { navigate } from '@reach/router';
import { ErrorScreen } from '../../ErrorScreen';
import Debug from './debug';
import { CrispAction } from '../../Crisp';
// import Debug from './debug'

class FormLoader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialValues: null,
      isProfilLoaded: false,
      isError: false,
      student: {},
    };
  }

  componentDidMount() {
    const _this = this;
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/inscription_biel.php/student/${this.props.id}`
      )
      .then(
        response => {
          if (response.data && Object.keys(response.data).length > 0) {
            if (response.data.status === 'paid') {
              navigate(`/${this.props.lang}/profile/${this.props.id}`);
            } else if (response.data.status === 'quotaExceeded') {
              navigate(`/${this.props.lang}/admission/quota/${this.props.id}`);
            } else if (response.data.status !== 'initiated') {
              navigate(`/${this.props.lang}/payment/${this.props.id}`);
            }
            _this.setState({
              initialValues: response.data,
              student: response.data,
              isProfilLoaded: true,
              isError: false,
            });
          } else {
            _this.setState({
              isError: true,
            });
          }
        },
        error => {
          _this.setState({
            isError: true,
          });
          console.log(error);
        }
      );
  }

  render() {
    const { initialValues, isError } = this.state;
    CrispAction(initialValues);
    return this.state.isProfilLoaded ? (
      // && Object.keys(this.state.settings).length > 0
      <Layout
        school={initialValues.school}
        locale={this.props.lang}
        apiData={initialValues}
      >
        <Helmet>
          <title>
            {this.props.lang === 'fr'
              ? `${
                  initialValues.firstName ? initialValues.firstName : 'Bonjour'
                }, complétez votre dossier d'admission ¬ ${
                  initialValues.school.title
                }`
              : `${
                  initialValues.firstName ? initialValues.firstName : 'Hello'
                }, complete your application ¬ ${initialValues.school.title}`}
          </title>
          <link rel="icon" href={getFavicon(initialValues.school.code)} />
        </Helmet>
        <StudentFunnel
          initialValues={this.state.initialValues}
          studentId={this.props.id}
          student={this.state.student}
          redirectToPayment={() => {
            navigate(`/${this.props.lang}/payment/${this.props.id}`);
          }}
        />
        <Debug data={this.state.initialValues} />
      </Layout>
    ) : !this.state.isProfilLoaded ? (
      <FormLoading isError={isError} />
    ) : Object.keys(this.state.settings).length === 0 ? (
      <ErrorScreen />
    ) : null;
  }
}

export default FormLoader;
