import React, { useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Text,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  ButtonGroup,
} from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';
import DocumentViewer from './DocumentViewer';
import { FormattedMessage, useIntl } from 'react-intl';
import CanvasDraw from 'react-canvas-draw';
import html2canvas from 'html2canvas';

const SignBox = ({ firstName, lastName, value, handleSign }) => {
  const locale = 'fr';
  const [hasSign, setHasSign] = useState(false);
  const currentDate = () => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    var todayDate = [];

    // today = dd + '/' + mm + '/' + yyyy;
    todayDate['fr'] = dd + '/' + mm + '/' + yyyy;
    todayDate['en'] = mm + '/' + dd + '/' + yyyy;

    return todayDate;
  };

  const signCanvas = React.createRef();

  return (
    <Box mt={10}>
      <Box
        w="320px"
        h="280px"
        border="solid 2px"
        borderColor="gray.400"
        id="signModule"
        bg="white"
      >
        <Text px={4} py={2} fontFamily="Caveat" fontSize="18px">
          <FormattedMessage id="read.and.approved.on" />
          &nbsp;{currentDate()[locale]}:
        </Text>
        <Text fontFamily="Caveat" px={4}>
          {firstName} {lastName}
        </Text>
        <Box px={4} py={2}>
          <Text fontSize="14px">Signature du formateur&nbsp;:</Text>
        </Box>
        <CanvasDraw
          ref={signCanvas}
          onChange={() => {
            setHasSign(true);
          }}
          loadTimeOffset={1}
          lazyRadius={4}
          brushRadius={1}
          brushColor={'#444'}
          catenaryColor={'#0a0302'}
          gridColor={'rgba(150,150,150,0.17)'}
          hideGrid={true}
          canvasWidth={315}
          canvasHeight={150}
          disabled={false}
          imgSrc={''}
          saveData={null}
          immediateLoading={false}
          hideInterface={false}
        />
      </Box>
      <ButtonGroup mt={4}>
        <Button
          onClick={() => {
            html2canvas(document.querySelector('#signModule'), {
              imageTimeout: 1000,
              scrollX: 0,
              scrollY: 0,
            }).then(canvas => {
              var signImage = canvas.toDataURL();
              handleSign(signImage);
              console.log(signImage);
            });
          }}
          isDisabled={!hasSign}
          colorScheme="blue"
          size="sm"
        >
          <FormattedMessage id="confirm.your.signature" />
        </Button>
        {hasSign ? (
          <Button
            onClick={() => {
              setHasSign(false);
              signCanvas.current.clear();
            }}
            size="sm"
          >
            <FormattedMessage id="button.delete" />
          </Button>
        ) : null}
      </ButtonGroup>
    </Box>
  );
};

export default SignBox;
