import { Box, Select } from '@chakra-ui/react';
import axios from 'axios';
import { useEffect, useState } from 'react';
import Debug from '../Form/debug';

export const LastDiplomaFieldBlock = ({ field, setHasUpdates }) => {
  const [data, setData] = useState();
  useEffect(() => {
    const getData = async () => {
      if (data) return;
      // console.log('get data');
      const request = await axios.get(
        `https://intranet.ad-education.com/api/v1/BREL/Get_DiplomasLevels/0`,
        {
          headers: { 'X-API-KEY': 'f3066df0984239de3804c99a720b23a4d1c26d63' },
        }
      );
      setData(request.data);
      // console.log(request);
      // .then(response => {
      //   console.log('response.data', response.data);
      //   setData(response.data);
      // })
      // .then(error => console.log('error', error));
    };
    if (!data) getData();
  }, [data]);

  const categories = Object.keys(data || {});

  return (
    <Box>
      {/* LastDiplomaFieldBlock <Debug data={categories} /> */}
      <Select
        placeholder={`Sélectionnez votre dernier diplôme`}
        id="last-diploma-select"
        {...field}
        onBlur={() => setHasUpdates()}
        color={field.value ? 'black' : 'gray.500'}
      >
        {categories.map(category => (
          <optgroup key={category} label={category}>
            {data[category].map(item => (
              //   <option value={item.id}>{item.label}</option>
              <option key={item.id} value={item.id}>
                {item.label}
              </option>
            ))}
          </optgroup>
        ))}
      </Select>
    </Box>
  );
};
