import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  SimpleGrid,
  Stack,
  Select,
  Textarea,
  Text,
  Heading,
  List,
  ListItem,
  Checkbox,
  HStack,
  VStack,
} from '@chakra-ui/react';
import { Field, ErrorMessage } from 'formik';
import Uploader from './Uploader';
import PhoneNumberField from './PhoneNumberField';
import { ReactSketchCanvas } from 'react-sketch-canvas';

// import AddressFields from './AddressFields'

// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import { useIntl, FormattedMessage } from 'react-intl';
import countriesClean from '../../Utils/countries-clean.js';
import fr from 'date-fns/locale/fr';
import getAge from '../../Utils/getAge';
import UploaderWithCrop from './UploaderWithCrop';
import FieldGroup from './FieldGroup';
import BrowseAndJoinDocument from './BrowseAndJoinDocument';

import { AddressField } from '../Address/AddressField';
import { handleRecordAdressComponents } from '../Address/handleRecordAdressComponents';
import Debug from './debug';
import { Step1ErrorNotifications } from './Step1ErrorNotifications';
import countries from '../../Utils/countries.js';
import { IconTextBox } from '../IconTextBox.jsx';
import { FaInfo } from 'react-icons/fa';
import dayjs from 'dayjs';

const Step1 = props => {
  const translations = useIntl()['messages'];
  const locale = useIntl()['locale'];
  const { school } = props;

  const [isStarted, setIsStarted] = useState(false);

  const age = dob => {
    var diff_ms = Date.now() - dob.getTime();
    var age_dt = new Date(diff_ms);
    return age_dt;
  };
  const sortCountries = (a, b) => {
    if (a.libelle_aurion_pays > b.libelle_aurion_pays) return 1;
    if (a.libelle_aurion_pays < b.libelle_aurion_pays) return -1;
  };
  if (locale === 'fr') {
    registerLocale('fr', fr);
    setDefaultLocale('fr');
  }

  const {
    errors,
    touched,
    values,
    setFieldValue,
    setFieldTouched,
    setFieldError,
    settings,
  } = props;
  const DateCustomInput = ({ value, onClick }) => (
    <Input
      focus="-1"
      background="white"
      value={value}
      placeholder="03/09/2002"
      onClick={onClick}
    />
  );

  useEffect(() => {
    if (!isStarted) {
      setFieldTouched('firstName', true, true);
      setFieldTouched('lastName', true, true);
      setFieldTouched('gender', true, true);
      setFieldTouched('birthDate', true, true);
      setFieldTouched('birthPlace_postalCode', true, true);
      setFieldTouched('phone', true, true);
      setFieldTouched('address', true, true);
      setFieldTouched('postalCode', true, true);
      setFieldTouched('profilePictFile', true, true);
      setFieldTouched('idCardPictFile', true, true);
      setIsStarted(true);
    }
  }, []);

  return (
    <Box>
      {school.code !== 'asf' && (
        <Box pb={4} display={settings.deadline ? 'block' : 'none'}>
          <IconTextBox icon={<FaInfo />}>
            <VStack spacing={'2'} alignItems="flex-start">
              <Text
                textTransform="uppercase"
                fontWeight={700}
                fontSize="sm"
                letterSpacing="widest"
              >
                Date butoir
              </Text>
              <Text>
                Votre formulaire d'inscription est ouvert jusqu'au{' '}
                <Text as="span" fontWeight="bold">
                  {/* {settings.deadline} */}
                  {dayjs(settings.deadline).format('DD/MM/YYYY')}
                </Text>
                . Passée cette date, votre place ne sera plus prioritaire et le
                formulaire sera bloqué.
              </Text>
            </VStack>
          </IconTextBox>
        </Box>
      )}

      <Stack spacing={5}>
        {locale === 'fr' ? (
          <Text fontSize="14px" color="gray.600">
            Tous les champs marqués{' '}
            <Text as="span" color="red.700">
              *
            </Text>{' '}
            sont obligatoires.
          </Text>
        ) : (
          <Text fontSize="14px" color="gray.700">
            All the fields marked with{' '}
            <Text as="span" color="red.700">
              *
            </Text>{' '}
            are mandatory.
          </Text>
        )}

        <FieldGroup title="Civilité">
          <SimpleGrid columns={{ base: 1, lg: 2 }} gap={4}>
            <Field name="firstName">
              {({ field, meta, form }) => (
                <FormControl isInvalid={errors.firstName && touched.firstName}>
                  <FormLabel htmlFor="firstName">
                    <FormattedMessage id="label.firstname" />
                    <Text as="sup" color="red.400" fontSize={14}>
                      *
                    </Text>
                  </FormLabel>
                  <Input
                    {...field}
                    bgColor="white"
                    id="firstName"
                    placeholder={translations['placeholder.firstname']}
                    isDisabled={
                      props.settings.readOnly.indexOf(field.name) != -1
                    }
                  />
                  <FormErrorMessage>{form.errors.firstName}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            {settings.school.showOtherfirstnamesField ? (
              <>
                <Field name="firstName2">
                  {({ field, meta, form }) => (
                    <FormControl
                      isInvalid={errors.firstName2 && touched.firstName2}
                    >
                      <FormLabel htmlFor="firstName2">
                        <FormattedMessage id="label.firstname" /> 2
                      </FormLabel>
                      <Input
                        {...field}
                        bgColor="white"
                        id="firstName2"
                        placeholder={translations['placeholder.firstname.2']}
                        isDisabled={
                          props.settings.readOnly.indexOf(field.name) != -1
                        }
                      />
                      <FormErrorMessage>
                        {form.errors.firstName2}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="firstName3">
                  {({ field, meta, form }) => (
                    <FormControl
                      isInvalid={errors.firstName && touched.firstName}
                    >
                      <FormLabel htmlFor="firstName3">
                        <FormattedMessage id="label.firstname" /> 3
                      </FormLabel>
                      <Input
                        {...field}
                        bgColor="white"
                        id="firstName3"
                        placeholder={translations['placeholder.firstname.3']}
                        isDisabled={
                          props.settings.readOnly.indexOf(field.name) != -1
                        }
                      />
                      <FormErrorMessage>
                        {form.errors.firstName3}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
              </>
            ) : null}

            <Box />

            {settings.school.showUseNameField ? (
              <>
                <Field name="useFirstName">
                  {({ field, meta, form }) => (
                    <FormControl
                      isInvalid={errors.useFirstName && touched.useFirstName}
                    >
                      <FormLabel htmlFor="useFirstName">
                        {/* <FormattedMessage id="label.firstname" /> */}
                        Prénom d'usage (facultatif) {field.value}
                      </FormLabel>
                      <Input
                        {...field}
                        bgColor="white"
                        id="usefirstName"
                        placeholder={translations['placeholder.use.firstname']}
                        // isDisabled={
                        //     props.settings.readOnly.indexOf(field.name) != -1
                        // }
                      />
                      <FormHelperText fontSize={`sm`}>
                        Si le prénom que vous utilisez au quotidien est
                        différent de celui de votre état civil
                      </FormHelperText>
                      <FormErrorMessage>
                        {form.errors.useFirstName}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Box />
              </>
            ) : null}

            <Field name="lastName">
              {({ field, form }) => (
                <FormControl
                  isInvalid={form.errors.lastName && form.touched.lastName}
                >
                  <FormLabel htmlFor="lastName">
                    <FormattedMessage id="label.birthname" />
                    <Text as="sup" color="red.400" fontSize={14}>
                      *
                    </Text>
                  </FormLabel>
                  <Input
                    {...field}
                    bgColor="white"
                    id="lastName"
                    placeholder={translations['placeholder.lastname']}
                    isDisabled={
                      props.settings.readOnly.indexOf(field.name) != -1
                    }
                  />
                  <FormErrorMessage>{form.errors.lastName}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            {settings.school.showUseLastNameField ? (
              <Field name="useLastName">
                {({ field, form }) => (
                  <FormControl
                    isInvalid={
                      form.errors.useLastName && form.touched.useLastName
                    }
                  >
                    <FormLabel htmlFor="useLastName">
                      <FormattedMessage id="label.usenameIfDifferent" />
                    </FormLabel>
                    <Input
                      {...field}
                      bgColor="white"
                      id="useLastName"
                      placeholder={translations['placeholder.usename']}
                      isDisabled={
                        props.settings.readOnly.indexOf(field.name) != -1
                      }
                    />
                    <FormErrorMessage>
                      {form.errors.useLastName}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            ) : null}
            <Field name="gender">
              {({ field, form }) => (
                <FormControl
                  isInvalid={form.errors.gender && form.touched.gender}
                >
                  <FormLabel htmlFor="gender">
                    <FormattedMessage id="label.gender" />
                    <Text as="sup" color="red.400" fontSize={14}>
                      *
                    </Text>
                  </FormLabel>
                  <Select
                    isDisabled={
                      props.settings.readOnly.indexOf(field.name) != -1
                    }
                    {...field}
                    value={form.values.gender}
                    id="gender"
                    name="gender"
                    bg="white"
                    placeholder={translations['label.what.is.your.gender']}
                  >
                    <option value="F">
                      {translations['placeholder.female']}
                    </option>
                    <option value="M">
                      {translations['placeholder.male']}
                    </option>
                    <option value="NB">
                      {translations['placeholder.non.binary']}
                    </option>
                  </Select>
                  <FormErrorMessage>{form.errors.gender}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
          </SimpleGrid>
        </FieldGroup>

        <FieldGroup title={`${translations['about.your.birth']}`}>
          <SimpleGrid columns={{ base: 1, lg: 2 }} gap={4}>
            <Field name="birthDate">
              {({ field, form, meta }) => (
                <FormControl isInvalid={errors.birthDate && touched.birthDate}>
                  <FormLabel htmlFor="birthDate">
                    <FormattedMessage id="label.birthdate" />
                    <Text as="sup" color="red.400" fontSize={14}>
                      *
                    </Text>
                  </FormLabel>
                  <Input
                    {...field}
                    bg="white"
                    type="date"
                    min="1920-01-01"
                    max="2022-01-01"
                  />
                  <ErrorMessage name={`birthDate`}>
                    {msg => <FormErrorMessage>{msg}</FormErrorMessage>}
                  </ErrorMessage>
                  {getAge(values.birthDate) && getAge(values.birthDate) > 0 ? (
                    <>
                      <FormHelperText>
                        <FormattedMessage id="your.age" /> :{' '}
                        {getAge(values.birthDate)}
                      </FormHelperText>
                    </>
                  ) : null}
                </FormControl>
              )}
            </Field>
            <Box />

            <Field name={`birthPlace_city`}>
              {({ field, form, meta }) => (
                <FormControl>
                  <FormLabel htmlFor="birthPlace">
                    {/* <FormattedMessage id="label.birthplace" /> */}
                    Ville de naissance :
                    <Text as="sup" color="red.400" fontSize={14}>
                      *
                    </Text>
                    <Input {...field} />
                  </FormLabel>
                </FormControl>
              )}
            </Field>

            <Field name={`birthPlace_postalCode`}>
              {({ field, form, meta }) => (
                <FormControl isInvalid={meta.error && meta.touched}>
                  <FormLabel htmlFor="birthPlace_postalCode">
                    {/* <FormattedMessage id="label.birthplace" /> */}
                    Code postal du lieu naissance :
                    <Text as="sup" color="red.400" fontSize={14}>
                      *
                    </Text>
                    <Input {...field} />
                    <FormErrorMessage>{meta.error}</FormErrorMessage>
                  </FormLabel>
                  {values.birthPlace_postalCode ? (
                    <FormHelperText>
                      {[`13`, `69`, `75`].includes(
                        values.birthPlace_postalCode.slice(0, 2)
                      )
                        ? `Si vous êtes né(e) à Paris, Marseille ou Lyon, indiquez le code postal de votre arrondissement de naissance.`
                        : null}
                    </FormHelperText>
                  ) : null}
                </FormControl>
              )}
            </Field>

            <Field name={`birthPlace_country`}>
              {({ field, form, meta }) => (
                <FormControl>
                  <FormLabel htmlFor="birthPlace">
                    {/* <FormattedMessage id="label.birthplace" /> */}
                    Pays du lieu naissance :
                    <Text as="sup" color="red.400" fontSize={14}>
                      *
                    </Text>
                    <Input {...field} />
                  </FormLabel>
                </FormControl>
              )}
            </Field>

            <Field name={`nationality`}>
              {({ field, form, meta }) => (
                <FormControl isInvalid={meta.error && meta.touched}>
                  <FormLabel htmlFor="birthPlace">
                    Nationalité :
                    <Text as="sup" color="red.400" fontSize={14}>
                      *
                    </Text>
                    <Select
                      {...field}
                      bg="white"
                      color={!field.value ? 'gray.400' : 'gray.800'}
                      placeholder="Sélectionnez"
                    >
                      {countries.sort(sortCountries).map(country => (
                        <option key={country.libelle_aurion_pays}>
                          {country.libelle_aurion_pays}
                        </option>
                      ))}
                    </Select>
                    <FormErrorMessage>
                      {form.errors.nationality}
                    </FormErrorMessage>
                    {/* <Input {...field} /> */}
                  </FormLabel>
                </FormControl>
              )}
            </Field>

            {/* <AddressField
                                initialValue={values.birthPlace_city && values.birthPlace_country ? `${values.birthPlace_postalCode} ${values.birthPlace_city} ${values.birthPlace_country}` : null}
                                newAddressCallback={
                                    (adressObject) => {
                                        handleRecordAdressComponents(
                                            adressObject,
                                            setFieldValue,
                                            setFieldTouched,
                                            'birthPlace_',
                                            null
                                        )
                                    }
                                }
                            /> */}

            {/* <AddressFieldBackup /> */}
            {/* <AddressFieldBackup /> */}

            {/* {values.birthPlace_country ?
                                <FormHelperText>
                                    <FormattedMessage id='placeholder.birthplace.notice' />&nbsp;:{' '}
                                    <strong>
                                        {values.birthPlace_city}
                                    </strong>{' '}
                                    {values.birthPlace_postalCode ?
                                        `(${values.birthPlace_postalCode})`
                                        : null}
                                    <strong>
                                        {`, ${values.birthPlace_country}`}
                                    </strong>


                                </FormHelperText>
                                :
                                <FormHelperText>
                                    <FormattedMessage id='placeholder.birth.city.country' />
                                </FormHelperText>
                            } */}

            {/* <Field name="birthPlace_postalCode">
                            {({ field, form, meta }) => (
                                <FormControl
                                    isInvalid={meta.error && meta.touched}
                                >
                                    <FormLabel htmlFor="birthPlace_postalCode">
                                        <FormattedMessage id="label.birthplace.confirmation" />
                                        <Text as='sup' color='red.400' fontSize={14}>*</Text>
                                    </FormLabel>
                                    <Input
                                        bg='white'
                                        {...field}
                                        maxLength='20'
                                    />
                                    <ErrorMessage name={`birthDate`}>
                                        {msg => <FormErrorMessage>{msg}</FormErrorMessage>}
                                    </ErrorMessage>
                                    {!values.birthPlace_postalCode ?
                                        <FormErrorMessage color='red.700'>
                                            <FormattedMessage id='label.birthplace.postal.code.missing.notice' />
                                        </FormErrorMessage>
                                        : null}
                                    <FormErrorMessage color='red.700'>
                                        {meta.error}
                                    </FormErrorMessage>

                                </FormControl>
                            )}
                        </Field> */}
          </SimpleGrid>
        </FieldGroup>

        <FieldGroup title={`Coordonnées`}>
          <Box>
            <Field name="phone">
              {({ field, form, meta }) => (
                <FormControl
                  isInvalid={form.errors.phone && form.touched.phone}
                >
                  <FormLabel htmlFor="phone">
                    <FormattedMessage id="label.phone.number" />
                    <Text as="sup" color="red.400" fontSize={14}>
                      *
                    </Text>
                  </FormLabel>
                  <PhoneNumberField
                    {...field}
                    phone={values.phone}
                    phonePrefix={values.phonePrefix}
                    setPhoneValue={phone => {
                      setFieldValue(`phone`, phone, true, true);
                      setFieldTouched(`phone`, true, true);
                    }}
                    setPhonePrefixValue={prefix => {
                      setFieldValue(`phonePrefix`, prefix, true, true);
                      setFieldTouched(`phonePrefix`, true, true);
                    }}
                  />
                  <FormErrorMessage>{form.errors.phone}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
          </Box>

          <SimpleGrid pt={4} columns={{ base: 1, lg: 2 }} gap={4}>
            <Field name={`address`}>
              {({ field, form, meta }) => (
                <FormControl isInvalid={meta.touched && meta.error}>
                  <FormLabel htmlFor="address">
                    {/* <FormattedMessage id="label.birthplace" /> */}
                    Numéro et rue de résidence :
                    <Text as="sup" color="red.400" fontSize={14}>
                      *
                    </Text>
                    <Input {...field} />
                  </FormLabel>
                </FormControl>
              )}
            </Field>

            <Field name={`city`}>
              {({ field, form, meta }) => (
                <FormControl isInvalid={meta.touched && meta.error}>
                  <FormLabel htmlFor="city">
                    {/* <FormattedMessage id="label.birthplace" /> */}
                    Ville :
                    <Text as="sup" color="red.400" fontSize={14}>
                      *
                    </Text>
                    <Input {...field} />
                  </FormLabel>
                </FormControl>
              )}
            </Field>

            <Field name={`postalCode`}>
              {({ field, form, meta }) => (
                <FormControl isInvalid={meta.touched && meta.error}>
                  <FormLabel htmlFor="postalCode">
                    {/* <FormattedMessage id="label.birthplace" /> */}
                    Code postal :
                    <Text as="sup" color="red.400" fontSize={14}>
                      *
                    </Text>
                    <Input {...field} />
                  </FormLabel>
                </FormControl>
              )}
            </Field>

            <Field name={`country`}>
              {({ field, form, meta }) => (
                <FormControl>
                  <FormLabel htmlFor="country">
                    {/* <FormattedMessage id="label.birthplace" /> */}
                    Pays :
                    <Text as="sup" color="red.400" fontSize={14}>
                      *
                    </Text>
                    <Input {...field} />
                  </FormLabel>
                </FormControl>
              )}
            </Field>

            {/* <FormControl>
                            <FormLabel htmlFor="address">
                                <FormattedMessage id="label.address" />
                                <Text as='sup' color='red.400' fontSize={14}>*</Text>
                            </FormLabel>
                            <AddressField
                                initialValue={`${values.address ? values.address : ''} ${values.postalCode ? values.postalCode : ''} ${values.city ? values.city : ''} ${values.country ? values.country : ''}`}
                                newAddressCallback={
                                    (adressObject) => {

                                        handleRecordAdressComponents(
                                            adressObject,
                                            setFieldValue,
                                            setFieldTouched,
                                            '',
                                            'address'
                                        )
                                    }
                                }
                            />

                            {values.country ?
                                <FormHelperText>
                                    <FormattedMessage id='placeholder.address.notice' />&nbsp;:{' '}
                                    <strong>
                                        {values.city}
                                    </strong>{' '}
                                    {values.postalCode ?
                                        `(${values.postalCode})`
                                        : null}
                                    <strong>
                                        {`, ${values.country}`}
                                    </strong>


                                </FormHelperText>
                                :
                                <FormHelperText>
                                    <FormattedMessage id='placeholder.address.city.country' />
                                </FormHelperText>
                            }

                            <FormHelperText>
                                <FormattedMessage id='placeholder.number.street.country' />.
                            </FormHelperText>
                        </FormControl> 

                        <Field name="postalCode">
                            {({ field, form, meta }) => (
                                <FormControl
                                    isInvalid={meta.error && meta.touched}
                                >
                                    <FormLabel htmlFor="postalCode">
                                        <FormattedMessage id="label.postalcode.confirmation" />
                                        <Text as='sup' color='red.400' fontSize={14}>*</Text>
                                    </FormLabel>
                                    <Input
                                        bg='white'
                                        {...field}
                                    />
                                    <ErrorMessage name={`postalCode`}>
                                        {msg => <FormErrorMessage>{msg}</FormErrorMessage>}
                                    </ErrorMessage>
                                    {!values.postalCode ?
                                        <FormErrorMessage color='red.700'>
                                            <FormattedMessage id='label.postal.code.missing.notice' />
                                        </FormErrorMessage>
                                        : null}
                                </FormControl>
                            )}
                        </Field>
                        */}
          </SimpleGrid>
        </FieldGroup>
        {/* <Debug data={values} /> */}
        <Box>
          {/* { !values.profilePictFileCropped ?  */}
          <UploaderWithCrop
            errors={errors}
            touched={touched}
            setFieldValue={value => {
              setFieldTouched('profilePictFileCropped');
              setFieldValue('profilePictFileCropped', value);
            }}
            initialValue={values.profilePictFileCropped}
          />
        </Box>
        <Box>
          <Field name="idCardPictFile">
            {({ field, form }) => (
              <FormControl
                isInvalid={touched.idCardPictFile && errors.idCardPictFile}
              >
                <FormLabel htmlFor="idCardPictFile">
                  <FormattedMessage id="label.your.id.document" />
                  <Text as="sup" color="red.400" fontSize={14}>
                    *
                  </Text>
                </FormLabel>
                <BrowseAndJoinDocument
                  userId={props.studentId}
                  fieldName="idCardPictFile"
                  uploadEndpoint="/inscription_biel.php/student/attach/"
                  value={values.idCardPictFile}
                  previewUrl={props.settings.idCardPictFile}
                  setFieldValue={value =>
                    setFieldValue('idCardPictFile', value)
                  }
                  isDisabled={false}
                />
                {/* <Debug data={values.idCardPictFile} /> */}
                <ErrorMessage name={`idCardPictFile`}>
                  {msg => <FormErrorMessage>{msg}</FormErrorMessage>}
                </ErrorMessage>
              </FormControl>
            )}
          </Field>
        </Box>

        <Field name={`exactInfoCertificate`}>
          {({ field, form, meta }) => (
            <FormControl isInvalid={meta.touched && meta.error}>
              <Box
                as="label"
                display="flex"
                bg="white"
                borderRadius="3px"
                border="solid 1px"
                borderColor="gray.100"
                p={3}
                radius={1}
                htmlFor={`exac`}
                justifyContent="space-between"
              >
                <Checkbox
                  {...field}
                  // defaultIsChecked={values[item.name]}
                  // isChecked={values.[item.name]}
                  w="100%"
                  flexShrink="1"
                  w="75%"
                >
                  <Text>{translations['valid.data.attestation']}</Text>
                </Checkbox>
              </Box>
              <FormErrorMessage>
                {errors['valid.data.attestation']}
              </FormErrorMessage>
            </FormControl>
          )}
        </Field>

        {props.school.socialNetworksField ? (
          <Box>
            <Field name="socialNetworks">
              {({ field, form }) => (
                <FormControl
                  isInvalid={
                    form.errors.socialNetworks && form.touched.socialNetworks
                  }
                >
                  <FormLabel htmlFor="socialNetworks">
                    <FormattedMessage id="label.socialNetworks" />
                  </FormLabel>
                  <Textarea
                    {...field}
                    bgColor="white"
                    id="socialNetworks"
                    placeholder={translations['placeholder.one.url.per.line']}
                  />
                  <FormHelperText>
                    Facebook, Behance, Instagram, ...
                  </FormHelperText>
                  <FormErrorMessage>
                    {form.errors.socialNetworks}
                  </FormErrorMessage>
                </FormControl>
              )}
            </Field>
          </Box>
        ) : null}

        {/* <Step1ErrorNotifications /> */}
        <Step1ErrorNotifications touched={touched} errors={errors} />
      </Stack>
      {/* <Debug data={errors} /> */}
      {/* <Debug data={values} /> */}
    </Box>
  );
};

export default Step1;
