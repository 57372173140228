import * as React from 'react';
import { Flex, Heading, Image, Text, VStack } from '@chakra-ui/react';
import { UnsubscribedLayout } from './layout';
export const UnsubscribedFormThankYou = ({ sessionId, lang }) => {
  const [data, setData] = React.useState(null);
  const local = lang === 'fr' ? 'fr' : 'en';

  React.useEffect(() => {
    async function getBackendData() {
      const backendRequest = await fetch(
        `${process.env.REACT_APP_API_URL}/inscription_biel.php/student/${sessionId}`
      );
      const backendResponse = await backendRequest.json();
      setData(backendResponse);
    }
    if (!data) getBackendData();
  }, [sessionId, data]);

  return (
    data && (
      //   <FunnelLayout school={data.school} locale={lang} apiData={data}>
      <UnsubscribedLayout apiData={data} locale={local}>
        <VStack mx="auto" maxW="xl" spacing={{ base: 10, lg: 12 }}>
          <Flex justify="center" bgColor="white" borderRadius="full">
            <Image
              w="160px"
              h="160px"
              objectFit="contain"
              src={data.school.logoUrl}
              alt={data.school.name}
            />
          </Flex>
          <Flex justify="center" bgColor="white" borderRadius="full">
            <Image
              w="303px"
              h="250px"
              objectFit="contain"
              src={`/Images/thank-you.svg`}
              alt={data.school.name}
            />
          </Flex>

          <Heading fontSize={{ base: `lg`, lg: `xl` }}>
            Merci pour vos précieux retours !
          </Heading>
          <Text textAlign="center">
            Nous vous souhaitons bonne chance dans votre nouveau projet ! Si
            vous avez la moindre question ou si vous changez d'avis, n'hésitez
            pas à reprendre contact avec nos équipes : {data.school.email}.
          </Text>
        </VStack>
      </UnsubscribedLayout>
      //   </FunnelLayout>
    )
  );
};
