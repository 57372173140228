import { Divider, Stack, Text } from '@chakra-ui/react';
import { BiTransferAlt } from 'react-icons/bi';

export const BankWireNotice = ({ data, school, title }) => {
  return (
    <Stack spacing={4}>
      <Divider />
      <Text fontSize="md" fontWeight="600" display="flex">
        {title ? title : <>Vous avez indiqué vouloir payer par Virement</>}
      </Text>
      <Text>
        Programmez, dès à présent, les virements automatiques aux dates prévues.
        <br />
        Mentionnez obligatoirement <strong>le motif de virement :</strong>{' '}
        {data?.lastName} {data?.firstName} - {data.diplomaName}
      </Text>
      <Text>
        <Stack>
          <Text>
            <strong>IBAN : </strong>
            {school.paymentInformationsData.iban}
          </Text>
          <Text>
            <strong>BIC : </strong>
            {school.paymentInformationsData.bic}
          </Text>
          <Text>
            <strong>Titulaire du compte : </strong>
            {school.paymentInformationsData.firmLabel}
          </Text>
        </Stack>
      </Text>
    </Stack>
  );
};

export const BankChequeNotice = ({ data, school, title }) => {
  return (
    <Stack spacing={4}>
      <Divider />
      <Text Text fontSize="md" fontWeight="600" d>
        {title ? title : <>Vous avez indiqué vouloir payer par chèque</>}
      </Text>
      <Text>
        Merci de nous faire parvenir {data.paymentOptions.paymentsNumber}{' '}
        {data.paymentOptions.paymentsNumber > 1 ? `chèques` : `chèque`}
        {data.paymentOptions.paymentsNumber > 1
          ? ` (un pour chacun des versements indiqués sur l'échéancier)`
          : null}{' '}
        à l'ordre de "
        <strong>{school.paymentInformationsData.chequeOrder}</strong>" et
        d'indiquer au dos de votre chèque les informations suivantes :{' '}
        <strong>
          {data?.lastName} {data?.firstName} - {data.diplomaName}
        </strong>
        .
      </Text>
    </Stack>
  );
};
