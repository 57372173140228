import * as React from 'react';
import Debug from '../Form/debug';
import FunnelLayout from '../Layouts/student-funnel';
import { Field, Form, Formik } from 'formik';
import { HeaderUnsubscribedForm } from './header';
import { FiSend } from 'react-icons/fi';

import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
  Text,
  Textarea,
  VStack,
} from '@chakra-ui/react';
import { unsubscribedFormSchema } from './schema';
import { UnsubscribedLayout } from './layout';
import axios from 'axios';
import { ChangeChoiceBanner } from './changeChoice';
import { navigate } from '@reach/router';
export const UnsubscribedForm = ({ sessionId, lang }) => {
  const [data, setData] = React.useState(null);
  const local = lang === 'fr' ? 'fr' : 'en';

  React.useEffect(() => {
    async function getBackendData() {
      const backendRequest = await fetch(
        `${process.env.REACT_APP_API_URL}/inscription_biel.php/student/${sessionId}`
      );
      const backendResponse = await backendRequest.json();
      setData(backendResponse);
    }
    if (!data) getBackendData();
  }, [sessionId, data]);

  const reasons = [
    {
      id: `otherSchool`,
      label: `J'ai choisi une autre école`,
    },
    {
      id: `parcoursSupAdmitted`,
      label: `Je suis admis à l'un de mes vœux Parcousup ou MonMaster`,
    },
    {
      id: `financialDifficulties`,
      label: `Je rencontre des difficultés financières`,
    },
    {
      id: `workStudyNotFound`,
      label: `Je n'ai pas trouvé l'alternance`,
    },
    {
      id: `changeOfOrientation`,
      label: `J'ai changé d'orientation/de projet`,
    },
    {
      id: `miscellaneous`,
      label: `Autre raison`,
    },
  ];

  const Asterix = () => (
    <Text as="sup" color="red.500" pl={1}>
      *
    </Text>
  );

  return (
    data && (
      //   <FunnelLayout school={data.school} locale={lang} apiData={data}>
      <UnsubscribedLayout apiData={data} locale={local}>
        <VStack mx="auto" maxW="xl" spacing={{ base: 10, lg: 12 }}>
          <HeaderUnsubscribedForm data={data} />
          <ChangeChoiceBanner data={data} sessionId={sessionId} />
          <Formik
            initialValues={{
              sessionId: sessionId,
              reason: undefined,
              detail: ``,
              comment: ``,
            }}
            validationSchema={unsubscribedFormSchema}
            onSubmit={async (values, actions) => {
              try {
                actions.setSubmitting(true);
                const request = await axios.post(
                  `${process.env.REACT_APP_API_URL}/api/v1/BREL/Post_UnsubscribeData/${sessionId}`,
                  values,
                  {
                    headers: {
                      'X-API-KEY': 'f3066df0984239de3804c99a720b23a4d1c26d63',
                    },
                  }
                );
                if (request.status === 200) {
                  navigate(`/${local}/unsubscribed/thank-you/${sessionId}`);
                } else {
                  alert('Backend request KO');
                  navigate(`/${local}/unsubscribed/thank-you/${sessionId}`);
                }
                actions.setSubmitting(false);
              } catch (error) {
                throw new Error(error);
              }
            }}
          >
            {({ values, isSubmitting, isValid }) => (
              <Form style={{ width: `100%` }}>
                <VStack spacing={{ base: 4, lg: 6 }} width="full" minW="full">
                  <Field name="reason">
                    {({ field, meta, form }) => (
                      <FormControl isInvalid={meta.error && meta.touched}>
                        <FormLabel fontSize="sm" fontWeight={700}>
                          Motif
                          <Asterix />
                        </FormLabel>
                        <Select
                          {...field}
                          placeholder="Sélectionnez votre motif"
                          color={field.value ? 'black' : 'gray.400'}
                        >
                          {reasons.map(reason => (
                            <option value={reason.id} key={reason.id}>
                              {reason.label}
                            </option>
                          ))}
                        </Select>
                        <FormErrorMessage>{meta.error}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>

                  {values.reason === `otherSchool` && (
                    <Field name="detail">
                      {({ field, meta, form }) => (
                        <FormControl isInvalid={meta.error && meta.touched}>
                          <FormLabel fontSize="sm" fontWeight={700}>
                            Nom de l'école
                            <Asterix />
                          </FormLabel>
                          <Input {...field} placeholer="" />
                          <FormErrorMessage>{meta.error}</FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  )}

                  {values.reason === `parcoursSupAdmitted` && (
                    <Field name="detail">
                      {({ field, meta, form }) => (
                        <FormControl isInvalid={meta.error && meta.touched}>
                          <FormLabel fontSize="sm" fontWeight={700}>
                            Nom de la formation
                            <Asterix />
                          </FormLabel>
                          <Input {...field} placeholer="Ex : MMI Bordeaux" />
                          <FormErrorMessage>{meta.error}</FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  )}

                  {values.reason === `miscellaneous` && (
                    <Field name="detail">
                      {({ field, meta, form }) => (
                        <FormControl>
                          <FormLabel fontSize="sm" fontWeight={700}>
                            Dites-nous tout !
                          </FormLabel>
                          <Input {...field} placeholer="" />
                        </FormControl>
                      )}
                    </Field>
                  )}

                  <Field name="comment">
                    {({ field, meta, form }) => (
                      <FormControl>
                        <FormLabel fontSize="sm" fontWeight={700}>
                          Commentaire
                        </FormLabel>
                        <Textarea
                          {...field}
                          placeholder={`Vous pouvez laisser un commentaire ici afin de nous en dire plus...`}
                        />
                      </FormControl>
                    )}
                  </Field>
                  <Flex justify="center">
                    <Button
                      type="submit"
                      bgColor="brand.1.500"
                      color="white"
                      display={'flex'}
                      justifyContent="center"
                      disabled={!isValid || isSubmitting}
                      _hover={{ bgColor: 'brand.1.600', color: 'white' }}
                    >
                      <Box mr={2}>
                        <FiSend />
                      </Box>
                      Envoyer
                    </Button>
                  </Flex>
                </VStack>
              </Form>
            )}
          </Formik>
        </VStack>
      </UnsubscribedLayout>
    )
  );
};
