import { HStack, Box, Text, VStack } from '@chakra-ui/react';

export const IconTextBox = ({ icon, children }) => {
  return (
    <HStack
      p="4"
      //   bgColor="#F6EFF6"
      bgColor="brand.1.100"
      color="brand.1.500"
      border="solid 1px"
      borderColor="brand.1.200"
      borderRadius="md"
      spacing={[0, 4]}
      style={{ alignItems: 'flex-start' }}
      wrap={['wrap', 'nowrap']}
    >
      <Box mb={[3, 0]}>
        <Box bgColor="brand.1.200" p={2} borderRadius={'md'}>
          {icon}
        </Box>
      </Box>
      {children}
    </HStack>
  );
};
