import React, { Component } from 'react'
import Helmet from 'react-helmet'
import axios from 'axios'
import {
    Heading,
    List,
    ListItem,
    Stack,
    Text

} from '@chakra-ui/react'
import getFavicon from '../Utils/getFavicon'
import Layout from './Layouts/student-funnel'
import { CheckCircleIcon } from '@chakra-ui/icons'
import ProgressBar from '../Components/Form/ProgressBar'
import { FormattedMessage } from 'react-intl'
import { CrispAction } from '../Crisp'
class PaymentSuccess extends Component {
    constructor(props) {
        super(props)
        this.state = {
            initialValues: {},
            student: null
        }
    }
    componentDidMount() {
        const _this = this
        // console.log('componentDidMount', this.props);
        axios.get(`${process.env.REACT_APP_API_URL}/inscription_biel.php/student/${this.props.id}`)
            .then(function (response) {
                // alert( JSON.stringify(response.data))
                _this.setState({
                    student: response.data
                })
            })
    }
    render() {
        const { student } = this.state
        CrispAction(student);
        return (
            student ?
                <Layout
                    school={student ? student.school : {}}
                    locale={this.props.lang}
                >
                    <Helmet>
                        <title>
                            {this.props.lang === 'fr' ?
                                `Félicitations, votre profil est complet ¬ ${student.school.title}`
                                :
                                `Congratulations, your profile is complete ¬ ${student.school.title}`
                            }
                        </title>
                        <link rel='icon' href={getFavicon(student.school.code)} />
                    </Helmet>
                    <ProgressBar step={5} />
                    <Stack
                        spacing={6}
                        px={{ base: 6, lg: 0 }}
                        mt={{ base: 10 }}
                    >
                        <Heading>
                            <FormattedMessage id="thankyou" /> {student.firstName}&nbsp;!
                        </Heading>
                        <Stack bg={{ base: 'none', lg: 'white' }} spacing={8} p={{ base: 0, lg: 8 }}>
                            <List spacing={4}>
                                <ListItem color='green.400'>
                                    <CheckCircleIcon mr={2} w={5} h={5} />
                                    Admission
                                </ListItem>
                                <ListItem color='green.400'>
                                    <CheckCircleIcon mr={2} w={5} h={5} />
                                    <FormattedMessage id="entering.your.informations" />
                                </ListItem>
                                <ListItem color='green.400'>
                                    <CheckCircleIcon mr={2} w={5} h={5} />
                                    {student.type === 'new'
                                        ?
                                        <FormattedMessage id="payment.administrative.fees" />
                                        :
                                        student.school.code === 'eac' || student.school.code === 'ing'
                                            ?
                                            <FormattedMessage id="payment.administrative.fees" />
                                            :
                                            <FormattedMessage id="payment.administrative.fees.renew" />
                                    }
                                </ListItem>
                            </List>
                            {this.props.lang === 'fr' ?
                                student.type === 'new'
                                    ?
                                    <Stack spacing={{ base: 1, lg: 2 }} shouldWrapChildren={true}>
                                        <Text>{`Votre inscription a bien été prise en compte.`}</Text>
                                        <Text>{`Vous allez recevoir un e-mail de confirmation du règlement des frais ainsi qu'une copie des documents lus et signés.`}</Text>
                                        <Text>Vous recevrez aussi le lien d'accès à un dernier formulaire permettant de compléter votre profil. Ce formulaire vous demandera vos numéros INE, sécurité sociale, CVEC, etc.</Text>

                                        <Text>{`Vous pouvez maintenant fermer cette page.`}</Text>
                                        <Text>{`À très vite !`}</Text>
                                    </Stack>
                                    :
                                    <Stack spacing={{ base: 1, lg: 2 }} shouldWrapChildren={true}>
                                        <Text>Votre réinscription a bien été prise en compte.</Text>
                                        <Text>Vous allez recevoir un e-mail de confirmation du règlement des arrhes ainsi qu'une copie des documents lus et signés.</Text>
                                        <Text>Vous recevrez aussi le lien d'accès à un dernier formulaire permettant de compléter votre profil. Ce formulaire vous demandera vos numéros INE, sécurité sociale, CVEC, etc.</Text>
                                        <Text>Vous pouvez maintenant fermer cette page.</Text>
                                        <Text>À très vite !</Text>
                                    </Stack>
                                :
                                student.type === 'new'
                                    ?
                                    <Stack spacing={{ base: 1, lg: 2 }} shouldWrapChildren={true}>
                                        <Text>{`Your registration has been taken into account.`}</Text>
                                        <Text>{`You will receive an e-mail confirming the payment of fees as well as a copy of the documents read and signed.`}</Text>
                                        <Text>{`You can now close this page.`}</Text>
                                        <Text>{`See you soon !`}</Text>
                                    </Stack>
                                    :
                                    <Stack spacing={{ base: 1, lg: 2 }} shouldWrapChildren={true}>
                                        <Text>Your registration has been taken into account.</Text>
                                        <Text>You will receive an e-mail confirming the payment of the deposit as well as a copy of the documents read and signed.</Text>
                                        <Text>You can now close this page.</Text>
                                        <Text>See you soon !</Text>
                                    </Stack>



                            }
                        </Stack>
                    </Stack>
                </Layout >
                : null
        )
    }
}

export default PaymentSuccess